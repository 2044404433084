"use client";

import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import defaultsDeep from "lodash/defaultsDeep";
import groupBy from "lodash/groupBy";
import isNil from "lodash/isNil";
import sortBy from "lodash/sortBy";

import { STAT_IDENTIFIER_TYPES } from "graphqlApi/client/mhcClient/statIdentifier/constants";

import {
  KpiPropsAndRelatedKpis,
  StatIdTopicInfoDictionary
} from "../util/fetchingFunctions/kpi/types";
import {
  MhcAttributionFragment,
  MhcTimeSeriesGranularityEnum,
  MhcTopicFragment
} from "graphqlApi/types";

import { kpiTitleOverrides } from "../content/kpis/dictionary";
import { fetchKpiProps } from "../util/fetchingFunctions/kpi";
import { IconId, IconIdEnum } from "common/components/Icons/utils";
import { logError } from "common/util/consoleHelpers";

import { ContentCard } from "layout/card/ContentCard";
import { ContentCardSectionTitle } from "layout/card/ContentCardSectionTitle";
import { KPI, KpiProps } from "common/components/KPI";
import { KPIIconKey } from "common/components/KpiList/KpiIconKey";
import ReportDates, { ReportDatesProps } from "common/components/ReportDates/ReportDates";
import { AttributionPopover } from "modules/ECDC/components/AttributionPopover";

interface Props {
  kpis: KpiPropsAndRelatedKpis[] | null;
  topic?: MhcTopicFragment;
  subtitle?: string;
  locationId: string;
  statIds?: string[];
  granularity: MhcTimeSeriesGranularityEnum;
  statIdTopicInfoDictionary?: StatIdTopicInfoDictionary;
  dates?: ReportDatesProps;
  attributionsByStat?: Record<string, MhcAttributionFragment[]>;
}

export const getFeaturedKpiProps = (kpiProps: KpiProps) => {
  return defaultsDeep(
    {
      enhancement: {
        showTarget: true,
        trendProps: {
          ...(kpiProps.enhancement?.trendProps ?? {}),
          showAllTicks: true
        }
      },
      sx: { mb: 0 }
    },
    kpiProps
  );
};

export const TopicKpis: React.FC<Props> = ({
  kpis = null,
  topic,
  subtitle,
  statIds = [],
  locationId,
  granularity,
  statIdTopicInfoDictionary,
  dates,
  attributionsByStat
}) => {
  const [loading, setLoadedState] = useState<boolean>(kpis ? false : true);
  const [loadedKpis, setLoadedKpis] = useState<KpiPropsAndRelatedKpis[] | null>(kpis);

  const getKpiTitle = (id: string, title: string | ReactElement): ReactElement | string => {
    const override = kpiTitleOverrides[id] ?? title;
    return override;
  };

  const sortKpisByType = (array: KpiPropsAndRelatedKpis[]) =>
    sortBy(array, ({ id }) => {
      const match = id.match(new RegExp(STAT_IDENTIFIER_TYPES.join("|")));
      return match ? STAT_IDENTIFIER_TYPES.indexOf(match[0]) : -1;
    });

  const showIconLegend = useMemo(() => {
    if (isNil(loadedKpis)) return false;
    return (
      loadedKpis?.filter(
        (props) => !isNil(props.enhancement?.targetProps) && props.enhancement?.showTarget === true
      ).length > 1
    );
  }, [loadedKpis]);

  useEffect(() => {
    if (loadedKpis === null && loading && statIds.length) {
      async () => {
        let _kpis: KpiPropsAndRelatedKpis[] = [];
        try {
          const { kpis } = await fetchKpiProps({
            statIds,
            locationId,
            options: {
              loadRelatedStats: true,
              statIdTopicInfoDictionary
            }
          });
          _kpis = kpis ?? [];
        } catch (error) {
          logError(error, "Error loading data for Topic KPIs");
        }
        setLoadedKpis(_kpis ?? []);
        setLoadedState(false);
      };
    }
  });

  const kpiGroups = useMemo(() => {
    const groups = groupBy(loadedKpis, ({ id }) =>
      id.replace(new RegExp(STAT_IDENTIFIER_TYPES.join("|")), "")
    );
    Object.entries(groups).forEach(([id, kpis]) => (groups[id] = sortKpisByType(kpis)));
    return groups;
  }, [loadedKpis]);

  if (!loadedKpis?.length || !topic) {
    return null;
  }

  const iconsToShow = [IconIdEnum.Improving] as IconId[];
  iconsToShow.push(IconIdEnum.NeedsAttention);

  const renderSingleKpi = (kpiProps: KpiPropsAndRelatedKpis, displayTitle = false) => (
    <Box>
      {kpiProps.kpiGroupTitle && displayTitle && (
        <ContentCardSectionTitle title={kpiProps.kpiGroupTitle} />
      )}
      <Grid item xs={12} display={"flex"}>
        <KPI
          {...getFeaturedKpiProps(kpiProps)}
          valueSize="x-large"
          fillContainer
          title={getKpiTitle(kpiProps.id, kpiProps.title ?? "")}
        />
      </Grid>
    </Box>
  );

  const renderKpiGroup = (group: KpiPropsAndRelatedKpis[]) => {
    const [primaryKpi, ..._relatedKpis] = group;
    // Remove trend props from related KPIs so a trend is only rendered in the primary KPI
    const relatedKpis = _relatedKpis.map((kpi) => ({
      ...kpi,
      enhancement: {
        ...kpi.enhancement,
        trendProps: null
      }
    }));
    return (
      <Box>
        {primaryKpi?.kpiGroupTitle && (
          <ContentCardSectionTitle title={primaryKpi?.kpiGroupTitle ?? ""} />
        )}
        <Grid container spacing={2}>
          {primaryKpi && (
            <Grid item xs={12} display={"flex"}>
              <KPI {...getFeaturedKpiProps(primaryKpi)} valueSize="x-large" fillContainer />
            </Grid>
          )}
          <Grid container item xs={12} display={"flex"} spacing={2}>
            {relatedKpis?.map((relatedKpiProps: KpiProps, i: number) => (
              <Grid key={i} item xs={12} md={6} display={"flex"}>
                <KPI
                  {...relatedKpiProps}
                  valueSize="large"
                  sx={{ ...relatedKpiProps.sx, mb: 0 }}
                  fillContainer
                  title={getKpiTitle(relatedKpiProps.id, relatedKpiProps.title ?? "")}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <ContentCard
      title="Key Data and Reports"
      subtitle={subtitle}
      headerChildren={dates ? <ReportDates granularity={granularity} {...dates} /> : undefined}
    >
      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ minHeight: 100 }}>
          <CircularProgress />
        </Box>
      )}
      {loadedKpis?.length && (
        <Box display="flex" flexDirection="column" gap={4}>
          {showIconLegend && (
            <KPIIconKey title="Icon" iconsToShow={iconsToShow} alignRight={false} />
          )}
          <Box>
            {Object.values(kpiGroups)?.map((group, i) => {
              const attributions = attributionsByStat
                ? group.flatMap(({ id }) => attributionsByStat[id] ?? []) ?? []
                : null;
              return (
                <Box mb={i === loadedKpis.length - 1 ? 0 : 5} key={i}>
                  {group.length > 1 && renderKpiGroup(group)}
                  {group.length === 1 && renderSingleKpi(group[0] as KpiPropsAndRelatedKpis, false)}
                  {attributions && (
                    <AttributionPopover sx={{ mt: 2 }} attributions={attributions ?? []} />
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </ContentCard>
  );
};
