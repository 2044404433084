import { useMemo } from "react";
import { useRouter } from "next/navigation";
import { Box, ButtonProps, Divider, Stack, Typography } from "@mui/material";
import isNil from "lodash/isNil";

import {
  MhcStatIdentifier,
  MhcStatIdentifierFragment,
  MhcTimeSeriesGranularityEnum
} from "graphqlApi/types";

import { updateLocationOnCurrentPath } from "common/components/LocationSwitcher/util/updateLocationOnCurrentPath";
import { sendGaNavigationEvent } from "common/util/googleAnalytics";
import { useIsMobile } from "common/util/hooks/useIsMobile";
import useRouterPath from "common/util/hooks/usePathWithParamKeys";

import { useNavigationStore } from "common/state/useNavigationStore";
import { CloseButton } from "component/button";
import { PillButton } from "component/button/PillButton";
import { MapFeature, MapMouseEvent } from "../BaseMap";
import Result from "./Result";

export interface GeoMapPopoverResult {
  nameOfIndicator?: string;
  colorCode?: string;
  colorCodeBorder?: string;
  statIdentifier?: MhcStatIdentifier | MhcStatIdentifierFragment;
  description?: string;
  result?: number | null;
  formattedResult?: string;
  date?: string | null;
  granularity?: MhcTimeSeriesGranularityEnum | null;
  index: number;
}

export interface GeoMapPopoverProps {
  title?: string;
  locationName?: string;
  locationId?: string;
  dateRange?: string;
  buttonHref?: string;
  closePopoverFunction?: React.Dispatch<React.SetStateAction<MapFeature | undefined>>;
  buttonCallback?: (selectedId: string) => void | string;
  buttonTitle?: string;
  width?: string;
  allowEmpty?: boolean;
  notSelectedTitle?: string;
  results?: GeoMapPopoverResult[];
  onlyShowName?: boolean;
  selectedSiId?: string;
  valueMap?: Record<string, GeoMapPopoverResult>;
  dateRangeMap?: Record<string, string>;
  pillButtonSx?: ButtonProps;
  onlyUpdateRouteLocation?: boolean;
  onFeatureClick?: MapMouseEvent;
  disableCallbackButton?: boolean;
  onNavigationComplete?: () => void;
}

const popoverStyle = ({
  isMobile = false,
  width = "250px"
}: {
  isMobile: boolean;
  width?: number | string;
}) => {
  const containerBoxSx = {
    boxShadow:
      "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    width,
    padding: 2,
    display: "flex",
    flexDirection: "column",
    gap: 0.75,
    mt: 2,
    mr: 2,
    background: "white",
    pointerEvents: "all",
    zIndex: 1000
  };

  let mobileInfoBoxStyle = {};
  if (isMobile) {
    mobileInfoBoxStyle = {
      top: "auto",
      bottom: 0,
      margin: 0,
      width: "100%",
      boxShadow: "none",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "borders.light",
      borderRadius: 0,
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "4px"
    };
  }
  return { containerBoxSx, mobileInfoBoxStyle };
};

export const TeasingMapPopover: React.FC = () => {
  const isMobile = useIsMobile();
  const { containerBoxSx, mobileInfoBoxStyle } = popoverStyle({ isMobile });
  return (
    <Box
      className="info leaflet-top leaflet-right"
      sx={{ ...containerBoxSx, py: "7px", px: "16px", ...mobileInfoBoxStyle }}
    >
      <Typography variant="caption" lineHeight="16px" color="light.secondary">
        {`${isMobile ? "Tap" : "Click"} on a region to see more info`}
      </Typography>
    </Box>
  );
};

export const GeoMapPopover: React.FC<GeoMapPopoverProps> = ({
  title,
  locationName,
  locationId,
  dateRange: _dateRange,
  buttonHref,
  buttonCallback,
  buttonTitle,
  width = "250px",
  results: _results,
  allowEmpty,
  onlyShowName,
  valueMap,
  dateRangeMap,
  selectedSiId,
  pillButtonSx,
  closePopoverFunction,
  onFeatureClick,
  onlyUpdateRouteLocation,
  disableCallbackButton,
  onNavigationComplete
}) => {
  // This should be optimized with the new investigate map component vvv
  const results = useMemo(() => {
    return valueMap && selectedSiId ? [valueMap[selectedSiId]] : _results;
  }, [valueMap, selectedSiId, _results]);
  const dateRange = dateRangeMap && selectedSiId ? [dateRangeMap[selectedSiId]] : _dateRange;
  const router = useRouter();
  const { pathWithParamKeys, params } = useRouterPath();
  const setIsNavigating = useNavigationStore((store) => store.setIsNavigating);
  const locationLink = useMemo(() => {
    return updateLocationOnCurrentPath({ locationId, pathname: pathWithParamKeys, params });
  }, [locationId, pathWithParamKeys, params]);

  const displayEmpty = useMemo(() => {
    const nonEmptyResults = results?.filter((r) => !isNil(r?.result));
    return (isNil(nonEmptyResults) || nonEmptyResults?.length === 0) && allowEmpty;
  }, [allowEmpty, results]);

  const { containerBoxSx, mobileInfoBoxStyle } = popoverStyle({ isMobile: useIsMobile(), width });
  if (!locationName || ((!results || results.length === 0) && !allowEmpty))
    return <TeasingMapPopover />;
  return (
    <Box
      className="info leaflet-top leaflet-right"
      id="leaflet-custom-popover"
      sx={{ ...containerBoxSx, ...mobileInfoBoxStyle, cursor: "default" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          p: 0
        }}
        data-testid="geo-map-popover-title"
      >
        {(title || locationName) && (
          <Typography variant="body1" fontWeight={700} color="light.primary">
            {title || locationName}
          </Typography>
        )}
        <CloseButton
          sx={{ ml: "auto", pointerEvents: "all" }}
          data-testid="geo-map-popover-close-button"
          onClick={() => {
            if (closePopoverFunction) closePopoverFunction(undefined);
            if (onFeatureClick) onFeatureClick(undefined);
          }}
        />
      </Box>
      {!onlyShowName && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {dateRange && (
            <Typography variant="caption" color="light.primary" sx={{ my: 0, py: 0 }}>
              {dateRange}
            </Typography>
          )}
          {results && results.length > 0 && (
            <Stack gap={1}>
              {results.map((result, index) => (
                <Result key={index} index={index} {...result} />
              ))}
            </Stack>
          )}
          {displayEmpty && (
            <>
              <Typography variant="h5" fontWeight={600} color="light.primary" sx={{ mt: "5px" }}>
                Data not reported
              </Typography>
              <Typography variant="caption" fontStyle="italic" color="light.primary">
                (In order to protect privacy, small values are not reported.)
              </Typography>
            </>
          )}
          {(buttonCallback || buttonHref) && buttonTitle && (
            <>
              <Divider sx={{ my: "12px" }} />
              <PillButton
                fullWidth
                variant="outlined"
                disabled={disableCallbackButton}
                sx={
                  pillButtonSx
                    ? pillButtonSx
                    : ({ palette }) => ({
                        borderColor: palette.dropdowns.main,
                        "&.MuiButton-root": {
                          color: palette.dropdowns.main
                        }
                      })
                }
                onClick={() => {
                  if (disableCallbackButton === true) return;
                  setIsNavigating(true);
                  sendGaNavigationEvent({
                    category: "Maps",
                    action: "Go to report click",
                    label: locationName,
                    ui_location: window.location.pathname
                  });
                  if (onlyUpdateRouteLocation === true && locationLink) {
                    void router.push(locationLink);
                    onNavigationComplete?.();
                    return;
                  }
                  const btnCallbackResult = buttonCallback?.(locationId ?? "state") as
                    | string
                    | undefined
                    | null;
                  const href =
                    typeof btnCallbackResult === "string" ? (btnCallbackResult as string) : null;
                  if (href || buttonHref) {
                    void router.push((href || buttonHref) ?? "");
                    onNavigationComplete?.();
                  }
                }}
              >
                {buttonTitle}
              </PillButton>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};
