import { useMemo } from "react";
import { Box } from "@mui/material";
import compact from "lodash/compact";
import every from "lodash/every";
import { granularityTimeScaleLookup } from "common/components/charts/highchartsConfig";
import { SeriesLineOptions } from "highcharts";

import { ChartSeriesOptions } from "../../charts/Chart/types";
import { MhcLocationFragment, MhcStatIdentifier, MhcTimeSeries } from "graphqlApi/types";

import { noDataMessage } from "common/content/messages";
import { brandPalette } from "theme/colors";
import { colorConfig } from "../../charts/util/color/config";
import { statsToSeries, StatsToSeriesParams } from "../../charts/util/series";
import { formatValueByUnit } from "common/util/formatHelpers";
import { getValueOptionsFromSi } from "common/util/formatHelpers/statIdentifierHelpers";
import { readableGranularityName } from "common/util/timeSeriesGranularityHelpers";
import { LoadedDateSeries } from "modules/Topics/util/fetchingFunctions/fetchStatsForAllSections";

import { DataNotAvailableNote } from "common/components/Alerts/DataNotAvailableNote";
import { BarChartDatum, BarChartProps } from "common/components/charts/BarChart";
import { DynamicBarChart } from "common/components/charts/DynamicBarChart";
import { LineChartProps } from "common/components/charts/LineChart";
import IndicatorTableModalChartFallback from "common/components/IndicatorTable/IndicatorTableContentWrapper/IndicatorTableModalChartFallback";
import { DynamicChart } from "../../charts/DynamicChart";
import { IndicatorTableRow } from "../IndicatorTable";

interface Props {
  title: string;
  stateSeries?: MhcTimeSeries;
  series?: MhcTimeSeries;
  selectedRow: IndicatorTableRow;
  location: MhcLocationFragment;
  stateLocation?: MhcLocationFragment;
}

interface SeriesDataMethodArgs {
  statIdentifier: MhcStatIdentifier;
  timeSeries: LoadedDateSeries;
  location: MhcLocationFragment;
}

export const IndicatorTableModalChart: React.FC<Props> = ({
  title,
  series: _series,
  stateSeries,
  selectedRow,
  location,
  stateLocation
}) => {
  const locationSeries = useMemo(() => {
    return _series ?? selectedRow.timeSeries ?? { values: [], dates: [] };
  }, [_series, selectedRow.timeSeries]);
  const shouldUseBarChart = locationSeries?.values.length === 1 || stateSeries?.values.length === 1;

  const maxValue = useMemo(() => {
    const values = [
      ...locationSeries?.values.map((v) => v ?? 0),
      ...(stateSeries?.values || []).map((v) => v ?? 0)
    ];
    return Math.max(...values);
  }, [locationSeries, stateSeries]);

  const getTimeSeriesFromLocationStat = ({
    statIdentifier,
    timeSeries,
    location: seriesLocation
  }: SeriesDataMethodArgs): SeriesLineOptions => {
    const { id: locationId, name } = seriesLocation;
    return statsToSeries({
      stats: [
        {
          statIdentifier,
          timeSeries,
          __typename: "MhcLocationStat",
          id: locationId + statIdentifier.id
        }
      ] as StatsToSeriesParams["stats"],
      options: {
        [statIdentifier.id]: {
          type: "line",
          name,
          accessibility: {
            description: statIdentifier.name
          },
          lineWidth: locationId === location.id ? 3 : 2,
          dashStyle: locationId !== location.id ? "Dash" : undefined,
          color: locationId === location.id ? colorConfig.primary : colorConfig.secondary
        }
      }
    })[0] as SeriesLineOptions;
  };

  const getBarChartData = ({
    statIdentifier,
    timeSeries,
    location
  }: SeriesDataMethodArgs): BarChartDatum => {
    const datum: BarChartDatum = {
      name: location.name,
      y: timeSeries.values[0] ?? 0,
      accessibility: {
        description: statIdentifier.name
      }
    };
    return datum;
  };
  const tickInterval = (() => {
    const proposedInterval = Math.ceil(((maxValue || 5) * 1.1) / 10);
    if (proposedInterval >= 5) {
      return proposedInterval;
    }
    return 5;
  })();

  const series = (
    method: (args: SeriesDataMethodArgs) => SeriesLineOptions | BarChartDatum
  ): (BarChartDatum | SeriesLineOptions)[] => {
    const args: SeriesDataMethodArgs = {
      statIdentifier: selectedRow.si as MhcStatIdentifier,
      timeSeries: locationSeries,
      location
    };

    const _locationSeries = method(args);
    return compact([
      _locationSeries,
      stateLocation && stateSeries?.values.length
        ? method({ ...args, location: stateLocation, timeSeries: stateSeries })
        : null
    ]);
  };

  const chartableSeries = shouldUseBarChart
    ? series(getBarChartData)
    : series(getTimeSeriesFromLocationStat).flat();

  const hasLocationAndStateData = every(chartableSeries, (s: BarChartDatum | SeriesLineOptions) => {
    if ("y" in s) {
      return s.y !== null;
    } else if ("data" in s) {
      return s.data && (s?.data?.length ?? 0) > 0;
    }
  });

  let axisOptions: LineChartProps["yAxisOptions"] | BarChartProps["yAxisOptions"] = {};
  if (selectedRow?.target && selectedRow?.target?.value) {
    const additionalLabelOptions = shouldUseBarChart ? { rotation: 0, x: 5, y: 15 } : {};
    axisOptions = {
      plotLines: [
        {
          color: brandPalette.brandSecondary.main,
          dashStyle: "LongDash",
          width: 2,
          value: selectedRow.target.value,
          zIndex: 1,
          label: {
            text: selectedRow.target.value
              ? `Target: ${
                  formatValueByUnit({
                    value: selectedRow.target.value,
                    ...getValueOptionsFromSi(selectedRow.si)
                  }) as string
                }`
              : "",
            style: {
              color: brandPalette.brandSecondary.main,
              fontWeight: "bold"
            },
            ...additionalLabelOptions
          }
        }
      ]
    };
  }
  const granularity = selectedRow.granularity || selectedRow.timeSeries?.granularity;

  const statIdentifier = selectedRow?.si;

  return (
    <Box data-testid="indicator-modal-chart">
      {!hasLocationAndStateData && <DataNotAvailableNote note={noDataMessage("trendline")} />}
      {shouldUseBarChart && hasLocationAndStateData && (
        <DynamicBarChart
          title={title}
          percent={statIdentifier?.unit === "percent" ?? undefined}
          height={2 * 80 + 40}
          precision={statIdentifier?.precision ?? 0}
          maxValue={(maxValue || 5) * 1.1}
          valuesDescription={statIdentifier?.unitLabel ?? ""}
          data={chartableSeries as BarChartDatum[]}
          yAxisOptions={axisOptions as BarChartProps["yAxisOptions"]}
          fallback={<IndicatorTableModalChartFallback />}
        />
      )}
      {!shouldUseBarChart && hasLocationAndStateData && (
        <DynamicChart
          title={title}
          statIdentifier={statIdentifier}
          timeScale={granularityTimeScaleLookup[granularity ?? "year"] ?? "yearly"}
          granularity={granularity ?? "year"}
          series={chartableSeries.flat() as ChartSeriesOptions[]}
          xAxisTitle={readableGranularityName(granularity)}
          yAxisOptions={{
            tickInterval,
            ...axisOptions
          }}
          fallback={<IndicatorTableModalChartFallback />}
        />
      )}
    </Box>
  );
};
