import { TopicDictionaryKey } from "../../pages/dictionary";
import DefaultOverview, { DefaultOverviewProps } from "./DefaultOverview";
import { topicOverviewDictionary } from "./dictionary";

type getOverviewContentReturnType = {
  component: React.ComponentType | React.FC<DefaultOverviewProps>;
  type: "custom" | "default";
};

export const getOverviewContent = (id: TopicDictionaryKey): getOverviewContentReturnType => {
  const custom = topicOverviewDictionary[id];
  return {
    component: custom || DefaultOverview,
    type: custom !== undefined ? "custom" : "default"
  };
};
