import { getMhcTimeSeries } from "graphqlApi/legacy/mhcClient";

import { DateFromApi } from "graphqlApi/customTypes";
import {
  MhcGeographyEnum,
  MhcStatIdentifier,
  MhcTimeSeries,
  MhcTimeSeriesGranularityEnum
} from "graphqlApi/types";

import { getBoundsOfInvestigationData } from "./util/helpers";
import { logError } from "common/util/consoleHelpers";
import { getLatestDateFromArray } from "common/util/getLatestDate";

import { formatDateTime } from "../ReportDates/ReportDates";
import { InvestigateMapInvestigation } from "./InvestigateMap";

export interface InvestigateMapLoadStatIdentifierReturn
  extends Pick<InvestigateMapInvestigation, "data"> {
  loadedStatIdentifiers: { [id: string]: MhcStatIdentifier };
  latestDataDate: DateFromApi;
  minMax: InvestigateMapInvestigation["minMax"];
}

interface LocationIdsGeography {
  geography: MhcGeographyEnum;
  ids: string[];
}

export interface InvestigateMapLoadStatIdentifierProps {
  statId: string;
  locationIds: LocationIdsGeography[];
  optionId?: string;
  granularity?: MhcTimeSeriesGranularityEnum | "ignore";
  startDate?: string;
  endDate?: string;
}

export const investigateMapLoadStatIdentifiers = async ({
  statId,
  locationIds,
  granularity = "week",
  startDate,
  endDate
}: InvestigateMapLoadStatIdentifierProps): Promise<InvestigateMapLoadStatIdentifierReturn> => {
  const data: InvestigateMapInvestigation["data"] = {};
  const loadedStatIdentifiers: { [id: string]: MhcStatIdentifier } = {};
  const loadedDateSeries: Partial<{
    [key in MhcGeographyEnum]: {
      timeSeries: MhcTimeSeries;
      locationId: string;
      geo: MhcGeographyEnum;
    }[];
  }> = {};
  const minMax: Partial<InvestigateMapInvestigation["minMax"]> = {};
  const maxDatesPerGeography: Partial<{ [key in MhcGeographyEnum]: DateFromApi }> = {};
  await Promise.all(
    locationIds.map(async (reference) => {
      const allDates: string[] = [];
      await Promise.all(
        reference.ids.map(async (locationId) => {
          try {
            const { timeSeries, statIdentifier } = await getMhcTimeSeries({
              locationId,
              statId,
              granularity: granularity === "ignore" ? undefined : granularity,
              startsOn: startDate,
              endsOn: endDate
            });
            let lastDateWithValueIndex = 0;
            timeSeries.timestamps.forEach((date, index) => {
              const value = timeSeries.values[index];
              if (value !== undefined && value !== null) {
                lastDateWithValueIndex = index;
              }
            });
            const lastDate = (timeSeries.dates ?? [])[lastDateWithValueIndex];
            if (lastDate !== undefined && lastDate !== null) {
              allDates.push(lastDate);
            }
            const item = {
              timeSeries,
              locationId,
              geo: reference.geography
            };
            if (loadedDateSeries[reference.geography]) {
              loadedDateSeries[reference.geography]?.push(item);
            } else {
              loadedDateSeries[reference.geography] = [item];
            }
            loadedStatIdentifiers[statId] = statIdentifier as MhcStatIdentifier;
          } catch (err) {
            logError(
              err,
              `[DETAIL]: Time Series, ${locationId} ${statId} ${
                granularity === "ignore" ? "" : granularity
              }`
            );
          }
        })
      );
      const maxTimeSeriesDate = getLatestDateFromArray(allDates);
      maxDatesPerGeography[reference.geography] = maxTimeSeriesDate;
    })
  );

  Object.entries(loadedDateSeries).forEach(([geo, locations]) => {
    const acceptedEntries: InvestigateMapInvestigation["data"] = {};
    locations.forEach(({ timeSeries, locationId, geo }) => {
      const maxTimeSeriesDate = maxDatesPerGeography[geo];
      const valueIndex = timeSeries.dates.findIndex((date) => date === maxTimeSeriesDate);
      const value = timeSeries.values[valueIndex];
      if (valueIndex !== -1 && value !== undefined && value !== null && maxTimeSeriesDate) {
        const item = {
          value,
          date: formatDateTime(
            new Date(maxTimeSeriesDate),
            granularity === "ignore" ? "year" : granularity
          )
        };
        data[locationId] = item;
        acceptedEntries[locationId] = item;
      }
    });
    minMax[geo as MhcGeographyEnum] = getBoundsOfInvestigationData(acceptedEntries);
  });

  const maxTimeSeriesDate = getLatestDateFromArray(Object.values(maxDatesPerGeography));
  return {
    data,
    minMax: minMax as InvestigateMapInvestigation["minMax"],
    loadedStatIdentifiers,
    latestDataDate: maxTimeSeriesDate
  };
};
