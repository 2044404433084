"use client";

import { ReactElement } from "react";
import { usePathname } from "next/navigation";
import { Link as MuiLink } from "@mui/material";

import { FcWithChildren } from "../types";

import { scrollToElementWithHeaderOffset } from "common/util/scroll";

interface Props {
  target: string;
  title: string;
  children?: ReactElement | ReactElement[];
  accountForOverlappingElements?: boolean;
}

export const LinkWithSmoothScroll: FcWithChildren<Props> = ({
  target,
  title,
  children,
  accountForOverlappingElements = false
}) => {
  const pathname = usePathname() ?? "";

  return (
    <MuiLink
      href={`#${target}`}
      onClick={(e) => {
        e.preventDefault();
        const element = document.getElementById(target);
        history.pushState(undefined, "My Healthy Community", `${pathname ?? ""}#${target}`);
        if (element) {
          !accountForOverlappingElements && element.scrollIntoView({ behavior: "smooth" });
          accountForOverlappingElements && scrollToElementWithHeaderOffset({ element });
        }
      }}
    >
      {children || title}
    </MuiLink>
  );
};
