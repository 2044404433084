import { Alert, Stack } from "@mui/material";

export const ChartStackAlert = () => {
  return (
    <Stack minHeight={250} justifyContent="center" sx={{ backgroundColor: "grey.50", p: 4 }}>
      <Alert severity="warning">
        There are not enough data points to render a chart for this indicator.
      </Alert>
    </Stack>
  );
};
