import { ReactNode, useMemo } from "react";
import Link from "next/link";
import { Box, Divider, Link as MuiLink, Stack, Typography } from "@mui/material";
import compact from "lodash/compact";
import isNil from "lodash/isNil";
import isNull from "lodash/isNull";
import last from "lodash/last";

import {
  MhcLocationFragment,
  MhcTargetFragment,
  MhcTimeSeries,
  MhcTopicFragment
} from "graphqlApi/types";

import { buildStatements, determineProgressIcons } from "common/components/KPI/KpiTarget/util";
import { formatDateByGranularity, formatValueByUnit } from "common/util/formatHelpers";
import {
  getValueOptionsFromSi,
  targetForStatIdentifier
} from "common/util/formatHelpers/statIdentifierHelpers";
import { useIsMobile } from "common/util/hooks/useIsMobile";
import { formatDateFromUTC, getUtcDateFromString } from "common/util/utcDateFromString";
import createTargetProps from "modules/Topics/util/fetchingFunctions/kpi/createTargetProps";
import { getStatTypeByStatId } from "modules/Topics/util/fetchingFunctions/kpi/util";

import { ChartWrapper } from "common/components/ChartWrapper/ChartWrapper";
import IndicatorTableModalChartFallback from "common/components/IndicatorTable/IndicatorTableContentWrapper/IndicatorTableModalChartFallback";
import { KpiIcons } from "common/components/KPI/KpiIcons";
import { MhcModal, MhcModalProps } from "common/components/MhcModal";
import { PopoverContainer } from "common/components/PopoverContainer";
import { AssociatedTopicsList } from "modules/ECDC/components/AssociatedTopicsList/AssociatedTopicsList";
import { StatAttributionList } from "modules/ECDC/components/StatAttributionList";
import { KpiTarget } from "../../KPI/KpiTarget";
import { IndicatorTableRow } from "../IndicatorTable";
import { IndicatorTableModalChart } from "./IndicatorTableModalChart";
import { WhatIsAgeAdjustedAndWhyItMattersPopover } from "./whatIsAgeAdjustedAndWhyItMattersPopover";

export interface IndicatorModalProps extends Omit<MhcModalProps, "children"> {
  location: MhcLocationFragment;
  isLoading: boolean;
  currentTopicSlug?: string;
  selectedRow?: IndicatorTableRow;
  stateLocation?: MhcLocationFragment;
  stateTimeSeries?: MhcTimeSeries;
  locationTimeSeries?: MhcTimeSeries;
  percentageChange?: string | null | undefined;
  title?: string;
}

const SectionTitle: React.FC<{ children: ReactNode }> = ({ children }) => (
  <Typography variant="h4" color="light.primary" sx={{ mb: 2 }}>
    {children}
  </Typography>
);

export const IndicatorModal: React.FC<IndicatorModalProps> = ({
  location,
  isLoading,
  currentTopicSlug,
  handleClose,
  open,
  selectedRow,
  stateLocation,
  locationTimeSeries,
  stateTimeSeries,
  container
}) => {
  const { si, topic, percentageChange } = selectedRow || {};
  const timeSeries = useMemo(() => {
    if (!isNil(locationTimeSeries)) {
      return locationTimeSeries;
    }
    return selectedRow?.timeSeries;
  }, [locationTimeSeries, selectedRow?.timeSeries]);
  const lastValue: number | null | undefined = timeSeries?.values?.length
    ? timeSeries.values[timeSeries.values.length - 1]
    : null;

  const isMobile = useIsMobile();
  const hasRelatedTopics = selectedRow?.si?.topics && selectedRow?.si?.topics.length > 0;

  const seriesLatestDate = timeSeries?.dates ? last(timeSeries?.dates) : "";

  const targetProps = useMemo(() => {
    if (selectedRow?.si === undefined) {
      return undefined;
    }
    const siTarget =
      (selectedRow?.target?.object as MhcTargetFragment | undefined) ??
      targetForStatIdentifier(selectedRow.si);
    if (siTarget?.value) {
      return createTargetProps({
        siTarget,
        lastValue,
        statIdentifier: selectedRow?.si,
        timeSeries: timeSeries
      });
    }
    return undefined;
  }, [lastValue, selectedRow?.si, selectedRow?.target?.object, timeSeries]);

  let progressIcons = null;
  const { shortStatement, longStatement } = useMemo(() => {
    if (targetProps) {
      return buildStatements({ ...targetProps });
    }
    return {
      shortStatement: undefined,
      longStatement: undefined
    };
  }, [targetProps]);
  progressIcons = useMemo(
    () => (shortStatement ? determineProgressIcons(shortStatement) : null),
    [shortStatement]
  );

  const title = useMemo(() => {
    let title = selectedRow?.si?.name;
    if (!title) return "";
    title = `${title} in ${location.name}`;
    if (selectedRow?.range && isNil(locationTimeSeries)) {
      const range = selectedRow?.range.includes("(")
        ? selectedRow?.range
        : `(${selectedRow?.range})`;
      return `${title} ${range}`;
    }
    const timeSeriesDates = timeSeries?.dates ?? [];
    const formatDate = (value = "") => {
      const date = getUtcDateFromString(value);
      if (!date) return;
      return formatDateByGranularity({ value: date, granularity: selectedRow?.granularity });
    };

    if (timeSeriesDates.length === 1) {
      let formattedDate = formatDate(selectedRow?.latestDate ?? "");
      formattedDate = formattedDate ? `(${formattedDate})` : "";
      title = [title, formattedDate].join(" ");
    } else if (timeSeriesDates.length > 1) {
      const dates = compact([
        formatDate(timeSeriesDates[0]),
        formatDate(timeSeriesDates[timeSeriesDates.length - 1])
      ]);
      title = [title, `(${dates.join(" - ")})`].join(" ");
    }

    return title;
  }, [
    selectedRow?.si?.name,
    selectedRow?.range,
    selectedRow?.granularity,
    selectedRow?.latestDate,
    location.name,
    locationTimeSeries,
    timeSeries?.dates
  ]);

  const valueOptions = useMemo(
    () => (selectedRow?.si ? getValueOptionsFromSi(selectedRow?.si) : {}),
    [selectedRow?.si]
  );

  return (
    <MhcModal
      open={open}
      handleClose={handleClose}
      title={title}
      caption={selectedRow?.si?.subtitle}
      useDivider
      width={isMobile ? "90%" : "85%"}
      data-testid={`${selectedRow?.si?.id ?? "indicator"}-modal`}
      container={container}
      modalPaperStyle={{ minHeight: "75vh" }}
      additionalHeaderContent={
        topic && (
          <Box textAlign="right" sx={{ whiteSpace: "nowrap" }}>
            <Typography>Topic Page</Typography>
            <Link href={topic.href} passHref>
              <MuiLink>{topic.name}</MuiLink>
            </Link>
          </Box>
        )
      }
    >
      <Stack sx={{ overflow: "auto", maxHeight: "75%", display: "flex", pt: { xs: 2, md: 0 } }}>
        {!isLoading && selectedRow && si && (
          <ChartWrapper hideEnlargeButton>
            <IndicatorTableModalChart
              title={title}
              stateSeries={stateTimeSeries}
              selectedRow={selectedRow}
              series={locationTimeSeries}
              location={location}
              stateLocation={stateLocation}
            />
          </ChartWrapper>
        )}
        {isLoading && <IndicatorTableModalChartFallback />}
        {!isNull(lastValue) && lastValue !== undefined && (
          <>
            <Divider sx={{ my: 2.5 }} />
            <SectionTitle>
              {getStatTypeByStatId(
                selectedRow?.si.id ?? "",
                selectedRow?.si.ageAdjusted ?? false,
                selectedRow?.si.isPercent ?? false
              )}{" "}
              {seriesLatestDate
                ? ` (${formatDateFromUTC(seriesLatestDate, selectedRow?.granularity) ?? ""})`
                : ""}
            </SectionTitle>
            <Stack maxWidth="100ch" gap={1}>
              <Stack direction="row">
                {progressIcons && (
                  <Typography fontSize="20px" component="p" sx={{ my: "auto" }}>
                    <KpiIcons icons={progressIcons} />
                  </Typography>
                )}
                <Box>
                  <Typography
                    component="span"
                    sx={{ fontSize: 20, lineHeight: "26px", fontWeight: 700 }}
                  >
                    {formatValueByUnit({ value: lastValue, ...valueOptions })}{" "}
                  </Typography>
                  {selectedRow?.si.statCaption && (
                    <Typography component="span" variant="body2">
                      {selectedRow?.si.statCaption}
                    </Typography>
                  )}
                </Box>
              </Stack>
              {targetProps && (
                <KpiTarget
                  {...targetProps}
                  hideLongStatement={false}
                  percentageChange={percentageChange}
                  locationStat={selectedRow?.locationStat}
                />
              )}
              {!targetProps && (percentageChange || shortStatement) && (
                <Typography component="p" variant="body1" fontWeight={700}>
                  Status:{" "}
                  <Typography component="span" variant="body1" fontWeight={400}>
                    {percentageChange || shortStatement}
                  </Typography>
                </Typography>
              )}
              {!targetProps && (longStatement || selectedRow?.locationStat?.sentence) && (
                <Typography variant="body1" fontWeight={400} sx={{ mb: "10px" }}>
                  {selectedRow?.locationStat?.sentence || longStatement}{" "}
                  {selectedRow?.locationStat?.needs_attention &&
                    selectedRow?.locationStat?.needs_attention}
                </Typography>
              )}
            </Stack>
            {selectedRow?.si.ageAdjusted && (
              <PopoverContainer
                id="whatIsAgeAdjustedAndWhyItMatters"
                content={<WhatIsAgeAdjustedAndWhyItMattersPopover />}
              >
                <Typography
                  role="button"
                  tabIndex={0}
                  component="p"
                  variant="caption"
                  color="component.links"
                  textAlign="left"
                  sx={{ cursor: "pointer", my: "auto" }}
                >
                  What is an age-adjusted rate and why does it matter?
                </Typography>
              </PopoverContainer>
            )}
          </>
        )}
        {selectedRow?.si?.attributions && (
          <>
            <Divider sx={{ my: 2.5 }} />
            <Box component="article" data-testid="indicator-modal-attribution-list">
              <SectionTitle>Data Sources</SectionTitle>
              <StatAttributionList
                attributions={si?.attributions || []}
                showDivider={false}
                showTitle={false}
                controlWidth={false}
              />
            </Box>
          </>
        )}
        {hasRelatedTopics && (
          <>
            <Divider sx={{ my: 2.5 }} />
            <Box component="article">
              <SectionTitle>Associated Topic Pages</SectionTitle>
              <AssociatedTopicsList
                showTitle={false}
                topics={
                  si?.topics?.filter(({ slug }) => slug !== currentTopicSlug) as MhcTopicFragment[]
                }
              />
            </Box>
          </>
        )}
      </Stack>
    </MhcModal>
  );
};
