import without from "lodash/without";

import { IdentifierConfig, TopicDashboardSubsectionConfig } from "../../dashboard/types";

import { columnIdsOptions } from "common/components/IndicatorTable/IndicatorTable";

const kpiStatIdConfigs: IdentifierConfig[] = [
  {
    identifier: "STRUCTURE_BUILT_PRE_1970_CENSUS_PERCENT"
  },
  {
    identifier: "HOUSING_RENTER_OCCUPIED_CENSUS_TOTAL_PERCENT"
  }
];

export const subSections: TopicDashboardSubsectionConfig[] = [
  {
    id: "housing",
    title: "Housing",
    kpiGroupConfig: {
      statIdConfigs: kpiStatIdConfigs,
      statIdsWithTrend: kpiStatIdConfigs.map(({ identifier }) => identifier)
    }
  },
  {
    id: "healthcare-access-and-resources",
    title: "Healthcare Access and Resources",
    tableConfig: {
      statIdConfigs: [
        "HEALTH_INSURANCE_CENSUS_TOTAL_PERCENT",
        "MEDIAN_HOUSEHOLD_INCOME_DOLLARS", // MEDIAN_HOUSEHOLD_INCOME_CENSUS_TOTAL_DOLLARS
        "CHILD_POVERTY_CENSUS_TOTAL_PERCENT"
      ],
      showWithNoData: false,
      props: {
        renderGhostColumns: false,
        columnsToShow: without(columnIdsOptions, "target")
      }
    }
  }
];
