"use client";

import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Stack, Typography } from "@mui/material";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";

import {
  BaseInvestigateChartProps,
  DatasetGroup,
  DefaultSeries,
  MappedDropdownOption
} from "./types";
import { MhcStratumGroupEnum, MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { seriesForTable } from "../ChartSeriesTable/util";
import { isRenderableChartSeries } from "../util/series";
import { capitalizeFirstLetter } from "common/util/capitalizeFirstLetter";
import { logError } from "common/util/consoleHelpers";
import { useIsMobile } from "common/util/hooks/useIsMobile";

import { MhcAlert } from "common/components/Alerts/MhcAlert";
import { TopicSectionAboutTheData } from "modules/Topics/components/TopicSectionAboutTheData";
import ChartSeriesTable from "../ChartSeriesTable";
import ConfidenceIntervalControls from "../ConfidenceIntervalControls";
import { DynamicChart } from "../DynamicChart";
import InvestigateChartClearSelection from "./InvestigateChartClearSelection";
import InvestigateChartTitles, {
  getChartTitle,
  InvestigateChartTitlesProps
} from "./InvestigateChartTitles";
import { InvestigateDropdown } from "./InvestigateDropdown";

export const InvestigateChart: React.FC<BaseInvestigateChartProps> = ({
  attributions = [],
  defaultSeries,
  groups,
  immutable,
  investigationBanner,
  investigationIsOptional = true,
  investigationLabels,
  locationName,
  showTable = false,
  suppressionNote,
  title,
  aboutAccordionTitle,
  accordionVariant,
  hideGroupTitle,
  ...props
}) => {
  const isMobile = useIsMobile();
  const defaultGroup = (() => {
    const def = groups?.filter((group) => group.default === true);
    if (def?.length > 0) {
      return def[0];
    }
    return undefined;
  })();
  const variant = (() => {
    if (groups === undefined || groups.length === 0) {
      logError("NO OPTIONS LOADED ON INVESTIGATE CHART COMPONENT");
      return undefined;
    }
    if (groups[0]?.options === undefined) {
      return "SingleInvestigation";
    }
    if (groups.length > 1) {
      return "NestedInvestigation";
    }
    return "Investigate";
  })();

  const mappedGroups: Record<string, DatasetGroup> = useMemo(() => {
    const result: Record<string, DatasetGroup> = {};
    groups.forEach((optionGroup) => {
      result[optionGroup.groupTitle] = { ...optionGroup };
    });
    return result;
  }, [groups]);

  const [selectedGroup, setSelectedGroup] = useState<DatasetGroup | undefined>(defaultGroup);

  const defaultOption = useMemo(() => {
    return selectedGroup?.defaultOption;
  }, [selectedGroup]);

  const [dataHasConfidenceIntervals, setDataHasConfidenceIntervals] = useState<boolean>(false);
  const [showConfidenceIntervals, setShowConfidenceIntervals] = useState<boolean>(false);

  // Map the received options to use the key as the value to prevent adding series with mismatching keys and values.
  const mappedOptions: Record<string, MappedDropdownOption> = useMemo(() => {
    if (selectedGroup === undefined) {
      return {};
    }
    const result: Record<string, MappedDropdownOption> = {};
    const options = selectedGroup?.options;
    if (options === undefined) {
      return result;
    }
    Object.keys(options).forEach((optionKey) => {
      // Check to see if the series is renderable (has more han 1 data point)
      if (!isRenderableChartSeries(options[optionKey]?.series ?? [])) return;
      result[options[optionKey]?.title || optionKey] = {
        ...options[optionKey],
        value: options[optionKey]?.title || optionKey
      } as MappedDropdownOption;
    });
    return result;
  }, [selectedGroup]);

  const [selectedOption, setSelectedOption] = useState<MappedDropdownOption | undefined>(
    mappedOptions[selectedGroup?.defaultOption?.title ?? ""]
  );

  const setNewOption = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSelectedOption(mappedOptions[event.target.value]);
    },
    [mappedOptions]
  );

  const clearSelection = () => {
    setSelectedOption(undefined);
  };

  const setNewGroup = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newGroup = mappedGroups[event.target.value];
      setSelectedGroup(newGroup);
      let newGroupOption = Object.values(newGroup?.options ?? []).find(
        ({ title }) => title === selectedOption?.title
      ) as MappedDropdownOption | undefined;
      if (newGroupOption) {
        newGroupOption = { ...newGroupOption, value: newGroupOption.title };
      }
      setSelectedOption(newGroupOption);
    },
    [mappedGroups, selectedOption?.title]
  );

  useEffect(() => {
    let hasConfidenceIntervals: boolean | undefined = false;
    const optionSeriesHasConfidenceIntervals = (opt?: MappedDropdownOption | DefaultSeries) =>
      opt?.series?.some(({ id }) => id?.includes("confidence")) ?? false;
    if (!selectedOption && defaultOption) {
      hasConfidenceIntervals = optionSeriesHasConfidenceIntervals(defaultOption);
    } else {
      hasConfidenceIntervals = optionSeriesHasConfidenceIntervals(selectedOption);
    }
    setDataHasConfidenceIntervals(hasConfidenceIntervals);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  const tableSeries = useMemo(() => {
    if (!showTable) return [];
    let series = selectedOption?.series ?? defaultSeries?.series ?? [];
    if (!selectedOption) {
      series = selectedGroup?.defaultOption?.series ?? defaultSeries?.series ?? [];
    }
    return seriesForTable(series);
  }, [defaultSeries, selectedGroup, selectedOption, showTable]);

  const titleProps = useMemo((): InvestigateChartTitlesProps | null => {
    if (!title) return null;
    return {
      title: title,
      stratificationTitle: selectedOption?.value ? selectedOption?.value : undefined,
      subtitle: selectedGroup?.subtitle,
      locationName: locationName,
      groupTitle: selectedGroup?.groupTitle,
      hideGroupTitle
    };
  }, [selectedOption, selectedGroup, title, locationName, hideGroupTitle]);

  const chartProps = useMemo(() => {
    const currentOption = selectedOption ?? selectedGroup?.defaultOption;
    if (!currentOption) return props;
    return {
      ...(currentOption?.chartOptions?.(props) ?? props)
    };
  }, [props, selectedGroup?.defaultOption, selectedOption]);

  return (
    <Stack gap={1.5}>
      {investigationBanner}
      <Stack gap={4}>
        {variant !== "SingleInvestigation" && (
          <Stack
            direction={{ xs: "column", lg: "row" }}
            gap={2}
            justifyContent="flex-start"
            flexWrap="wrap"
          >
            {variant === "NestedInvestigation" && (
              <InvestigateDropdown
                type="Chart"
                preventGrowth
                value={selectedGroup?.groupTitle}
                title={investigationLabels?.[0] ?? "Select indicator"}
                onChange={setNewGroup}
                defaultValue={defaultGroup?.groupTitle}
                placeholder={investigationLabels?.[0]}
                options={Object.values(mappedGroups).map((group) => ({
                  title: group.groupTitle,
                  value: group.groupTitle
                }))}
              />
            )}
            {Object.values(mappedOptions).length > 0 && (
              <>
                {variant === "NestedInvestigation" && !isMobile && (
                  <Typography
                    variant="body2"
                    fontWeight={600}
                    sx={{
                      display: { xs: "none", lg: "flex" },
                      flexGrow: "0",
                      alignSelf: "flex-end",
                      pb: 2
                    }}
                  >
                    and
                  </Typography>
                )}
                <InvestigateDropdown
                  preventGrowth
                  value={selectedOption?.value ?? undefined}
                  title={
                    <>
                      {investigationLabels?.[1] ?? "Select Investigation"}
                      {investigationIsOptional && (
                        <>
                          {" "}
                          <Typography component="span" style={{ fontWeight: 400 }}>
                            (optional)
                          </Typography>
                        </>
                      )}
                    </>
                  }
                  placeholder={investigationLabels?.[1] ?? "Select stratification"}
                  onChange={setNewOption}
                  defaultValue={selectedOption?.value}
                  options={sortBy(
                    Object.values(mappedOptions).map((option) => ({
                      title: option.title,
                      value: option.value
                    })),
                    "title"
                  )}
                  type="Chart"
                />
              </>
            )}
            {selectedOption && <InvestigateChartClearSelection handleClick={clearSelection} />}
          </Stack>
        )}
        {dataHasConfidenceIntervals && (
          <ConfidenceIntervalControls
            showing={showConfidenceIntervals}
            onChange={() => setShowConfidenceIntervals((prev) => !prev)}
          />
        )}
        {titleProps && <InvestigateChartTitles {...titleProps} />}
        {selectedOption || defaultSeries || defaultOption ? (
          <DynamicChart
            {...chartProps}
            statIdentifier={selectedOption?.statIdentifier ?? chartProps.statIdentifier}
            immutable={immutable ?? dataHasConfidenceIntervals ?? false}
            series={
              selectedOption?.series ??
              selectedGroup?.defaultSeries?.series ??
              defaultSeries?.series ??
              defaultOption?.series ??
              []
            }
            confidenceIntervals={(selectedOption ?? defaultOption)?.confidenceIntervals}
            title={titleProps ? getChartTitle(titleProps) : ""}
            subtitle={capitalizeFirstLetter(selectedGroup?.subtitle ?? "")}
            yAxisTitle={capitalizeFirstLetter(selectedGroup?.subtitle ?? "")}
            showConfidenceIntervals={showConfidenceIntervals}
            markersOn
            useNavigator={false}
            useScrollbar={false}
          />
        ) : (
          <Stack
            minHeight={375}
            sx={{ background: "#F5F5F5" }}
            alignItems="center"
            justifyContent="center"
            p={4}
          >
            <Typography component="span" variant="h3" color="light.secondary" textAlign="center">
              Select options above to show the data
            </Typography>
          </Stack>
        )}
        {showTable && (
          <ChartSeriesTable
            title={titleProps ? getChartTitle(titleProps) : null}
            subtitle={selectedGroup?.subtitle}
            series={tableSeries}
            granularity={props.granularity ?? MhcTimeSeriesGranularityEnum.Year}
            confidenceIntervals={(selectedOption ?? defaultOption)?.confidenceIntervals}
            showConfidenceIntervals={showConfidenceIntervals}
          />
        )}
        <Stack gap={2}>
          {suppressionNote && <MhcAlert severity="info">{suppressionNote}</MhcAlert>}
          {!!selectedOption &&
            ([MhcStratumGroupEnum.Race, MhcStratumGroupEnum.Ethnicity] as string[]).includes(
              selectedOption.value
            ) && (
              <MhcAlert severity="info">
                Race and ethnicity categories are not mutually exclusive so counts will not add up
                to 100%. Counts of less than 11 are suppressed.
              </MhcAlert>
            )}
          {attributions.length > 0 && (
            <TopicSectionAboutTheData
              dataSources={uniqBy(attributions, "id")}
              title={aboutAccordionTitle}
              variant={accordionVariant}
              uiLocation={`${title ?? ""} Investigate Chart`.trim()}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
