import { getMhcLocationStat } from "graphqlApi/legacy/mhcClient";

import { FetchKpiOptions, KpiPropsAndRelatedKpis } from "./types";
import { MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { getStatTypeByStatId } from "./util";
import { formatValueByUnit } from "common/util/formatHelpers";
import { formatDateFromUTC } from "common/util/utcDateFromString";

export const fetchRelatedKpi = async ({
  locationId,
  statId,
  options
}: {
  locationId: string;
  statId: string;
  options: FetchKpiOptions;
}): Promise<KpiPropsAndRelatedKpis> => {
  const { alternateValueCallback, granularity, valueCaptionCallback } = options;
  const locationStat = await getMhcLocationStat({
    locationId,
    statId
  });
  const { statIdentifier, lastValue, lastUpdatedOn, granularity: _granularity } = locationStat;
  const _statGranularity = _granularity ?? granularity ?? MhcTimeSeriesGranularityEnum.Year;
  const {
    unit,
    precision,
    unitLabel,
    description,
    addendum,
    subtitle,
    isPercent,
    ageAdjusted,
    statCaption
  } = statIdentifier;
  return {
    // additionalInformation: additionalInformation || null,
    id: statId,
    title: getStatTypeByStatId(statId, ageAdjusted, isPercent ?? false),
    isPercent: isPercent ?? false,
    value:
      ((alternateValueCallback && alternateValueCallback(lastValue ?? null)) ||
        formatValueByUnit({
          value: lastValue,
          unit: unit,
          precision: precision,
          isPercent
        })) ??
      null,
    statCaption:
      (valueCaptionCallback && valueCaptionCallback(lastValue ?? null)) ||
      statCaption ||
      subtitle ||
      addendum ||
      unitLabel,
    info: description ?? "",
    enhancement: {
      dataDatePeriod: formatDateFromUTC(lastUpdatedOn, _statGranularity) ?? ""
    },
    ageAdjusted: ageAdjusted ?? false
  };
};
