import { Box, SvgIconProps, Typography } from "@mui/material";

import { MhcStatImprovementEnum } from "graphqlApi/types";

import { showImprovingIcon } from "common/components/Icons/utils";
import { formatValueByUnit } from "common/util/formatHelpers";

import { ImprovingIcon, NeedsAttentionIcon } from "common/components/Icons";

interface ChangeLabelProps {
  change?: number;
  range?: string;
  needsAttention?: boolean;
  improvementType?: MhcStatImprovementEnum | null;
  maxWidth?: number | string;
}

export const ChangeLabel = ({
  change,
  range,
  needsAttention,
  improvementType,
  maxWidth
}: ChangeLabelProps) => {
  const iconSX: Omit<SvgIconProps, "color"> = {
    width: "25px",
    height: "25px",
    fontSize: "medium"
  };

  const formattedString =
    change === Infinity
      ? "from 0"
      : formatValueByUnit({
          value: change && Math.abs(change),
          unit: "percent",
          precision: 1,
          isPercent: true
        });

  const verbalChange = (() => {
    if (change === undefined) {
      return undefined;
    }
    if (change > 0) {
      return "Increased";
    }
    if (change < 0) {
      return "Decreased";
    }
    return "No change";
  })();

  const iconStyle = {
    mr: 1,
    ml: 0,
    p: 0,
    width: "25px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    my: "auto",
    "&:empty": {
      display: { xs: "none", md: "block" }
    }
  };

  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: { xs: "column", md: "row" },
        mb: { xs: 0, md: undefined },
        maxWidth
      }}
    >
      <Box
        sx={{
          display: "flex",
          mr: { xs: 0, md: 1 },
          flexDirection: { xs: "column", md: "row" }
        }}
      >
        <Box sx={iconStyle} className="svg-icon-wrapper">
          {needsAttention && <NeedsAttentionIcon {...iconSX} />}
        </Box>
        <Box sx={{ ...iconStyle, mr: 0.5 }} className="svg-icon-wrapper">
          {showImprovingIcon({ change, improvementType }) && <ImprovingIcon {...iconSX} />}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: { xs: "center", md: "baseline" },
          flexDirection: { xs: "column", md: "row" },
          gap: "3px"
        }}
      >
        <Typography component="span" variant="body2" fontWeight="400" sx={{ whiteSpace: "nowrap" }}>
          {(change !== undefined && verbalChange) || ""}{" "}
          {(change && change !== 0 && formattedString) || ""}
        </Typography>
        {range && (
          <Typography
            sx={{
              whiteSpace: "nowrap"
            }}
            variant="body3"
            color="textSecondary"
          >
            {range}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
