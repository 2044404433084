import isNil from "lodash/isNil";
import {
  getMhcMapDataWithStats,
  getMhcStatIdentifiersWithAvailableGeos
} from "graphqlApi/legacy/mhcClient";

import { InvestigateMapPropsV2 } from "common/components/InvestigateMap/V2/util/types";
import { MhcGeographyEnum, MhcLocation, MhcStatIdentifier } from "graphqlApi/types";
import { IdentifierConfig } from "modules/Topics/util/elementHelpers/dashboard/types";

import { ColorRangeName } from "common/components/GeoMap/utils";
import { groupAndTurnLocationsIntoFeatures } from "common/components/LocationSwitcher/util/groupAndTurnLocationsIntoFeatures";
import { logInfo } from "common/util/consoleHelpers";
import { sortGeographiesBySize } from "common/util/sortGeographiesBySize";
import { getIdentifiersOfStatIdConfigs } from "modules/Topics/util/fetchingFunctions/fetchSectionData";

export interface CreateInvestigateMapPropsType {
  availableGeographies: MhcGeographyEnum[];
  omitGeographies?: MhcGeographyEnum[];
  locationId?: string;
  stats: (string | IdentifierConfig | MhcStatIdentifier)[];
  defaultGeography?: MhcGeographyEnum;
  overrideDateByStatMap?: InvestigateMapPropsV2["overrideDateByStatMap"];
  colorRangeName?: ColorRangeName;
}

/**
 * Utility function utilized to remove the state from the available geographies of a list of stats
 *
 * Since Investigate maps shouldn't display the state as an option in the map type dropdown, the easiest
 * way to avoid this is to remove the geography from the available geographies property of the stats, currently
 * this is the implementation that requires less work on the investigate map. If in the future we decide there
 * might be cases when we do want to display the state geography, this function shouldn't be called.
 *
 * @param stats - Stat identifier list to which the filter will be applied
 *
 * @returns Stat identifier list of stats without the state in their available geographies
 */
const removeStateFromAvailableGeos = (stats: MhcStatIdentifier[]): MhcStatIdentifier[] => {
  return stats.map((stat) => ({
    ...stat,
    availableGeographies: stat.availableGeographies.filter((geo) => geo !== MhcGeographyEnum.State)
  }));
};

/**
 * Creates the basic configuration object for the investigate map
 *
 * @param props - configuration object
 * @param props.availableGeographies - determines which geographies should be displayed in the investigate map
 * @param props.omitGeographies - determines which geographies should be displayed in the investigate map
 * @param props.locationId - current selected location
 * @param props.stats - list of stat identifiers to be displayed in the investigate map
 * @param props.defaultGeography - initially selected and loaded geography (defaults to first geography in sorted list if not given)
 * @param props.overrideDateByStatMap -
 * @param props.colorRangeName - Name of the color range to be used in the map
 * @returns the basic configuration for the investigate map props
 */
export const createInvestigateMapProps = async ({
  availableGeographies,
  omitGeographies,
  locationId,
  stats,
  defaultGeography: _defaultGeography = MhcGeographyEnum.ZipCode,
  overrideDateByStatMap,
  colorRangeName
}: CreateInvestigateMapPropsType): Promise<InvestigateMapPropsV2 | null> => {
  if (availableGeographies.length === 0 || stats.length === 0) return null;

  let loadedStatIdentifiers: MhcStatIdentifier[] = stats as MhcStatIdentifier[];
  let statIds: string[] = typeof stats?.[0] === "string" ? (stats as string[]) : [];
  let statConfigs: IdentifierConfig[] | undefined;
  if (typeof stats[0] === "object" && "identifier" in stats[0]) {
    statConfigs = stats as IdentifierConfig[];
    statIds = getIdentifiersOfStatIdConfigs(statConfigs);
  }
  if (statIds.length) {
    loadedStatIdentifiers = (await getMhcStatIdentifiersWithAvailableGeos({
      ids: statIds
    })) as MhcStatIdentifier[];
  }

  const defaultGeography = (_defaultGeography ??
    sortGeographiesBySize(
      availableGeographies.filter((geo) =>
        omitGeographies ? !omitGeographies.includes(geo) : true
      )
    )[0]) as MhcGeographyEnum;
  const defaultStat = loadedStatIdentifiers[0];
  if (isNil(_defaultGeography) || isNil(defaultStat)) return null;

  const defaultStatConfig = statConfigs?.find(({ identifier }) => identifier === defaultStat.id);
  const _statIds = loadedStatIdentifiers.map((stat) => stat.id);

  if (process.env.DATA_LOADING_LOGGING_ENABLED) {
    logInfo("---------------------------------------------------------------------");
    logInfo("Fetching data for investigate Map");
    logInfo(" * statIds:");
    _statIds.forEach((id) => logInfo(`   ${id}`));
    logInfo(` * default geography: ${defaultGeography as MhcGeographyEnum}`);
    logInfo(
      `  * date range: ${defaultStatConfig?.startsOn ?? "NOT_SET"} - ${
        defaultStatConfig?.endsOn ?? "NOT_SET"
      }`
    );
    logInfo("---------------------------------------------------------------------\n");
  }
  const { startsOn = null, endsOn = null } = defaultStatConfig ?? {};
  const locations = await getMhcMapDataWithStats({
    geographies: [defaultGeography],
    statIds: _statIds,
    granularity: statConfigs?.[0]?.granularity,
    startsOn,
    endsOn
  });

  const { featureMap: mappedFeatures, minMaxByStat } = groupAndTurnLocationsIntoFeatures({
    locations: locations as MhcLocation[],
    selectedLocationId: locationId,
    addColor: true,
    overrideDateByStatMap,
    colorRangeName
  });

  return {
    defaultGeography,
    omitGeographies: omitGeographies ?? [],
    defaultStatId: defaultStat.id,
    initialGeoJsonByGeography: mappedFeatures,
    initialMinMaxRecord: minMaxByStat,
    stats: removeStateFromAvailableGeos(loadedStatIdentifiers),
    statConfigs: statConfigs ?? null,
    overrideDateByStatMap: overrideDateByStatMap ?? null
  };
};
