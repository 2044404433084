"use client";

import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Divider, Typography } from "@mui/material";

import { capitalizeFirstLetter } from "common/util/capitalizeFirstLetter";
import { useIsMobile } from "common/util/hooks/useIsMobile";

type LegendTitleProps = {
  title: string;
  handleCollapse: () => void;
};
export const LegendTitle: React.FC<LegendTitleProps> = ({ title, handleCollapse }) => {
  const isMobile = useIsMobile();
  const [collapsed, setCollapsed] = useState(false);

  const arrowIcon = (() => {
    if (collapsed) {
      return isMobile ? (
        <KeyboardArrowDownIcon fontSize="small" color="action" />
      ) : (
        <KeyboardArrowUpIcon fontSize="small" color="action" />
      );
    }
    return isMobile ? (
      <KeyboardArrowUpIcon fontSize="small" color="action" />
    ) : (
      <KeyboardArrowDownIcon fontSize="small" color="action" />
    );
  })();
  return (
    <Box
      onClick={() => {
        handleCollapse();
        setCollapsed((prev) => !prev);
      }}
    >
      <Typography
        fontSize="10px"
        fontWeight={400}
        lineHeight="13.62px"
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          justifyContent: "space-between",
          gap: 1
        }}
      >
        {capitalizeFirstLetter(title)}
        {arrowIcon}
      </Typography>
      {!collapsed && <Divider sx={{ my: "6px" }} />}
    </Box>
  );
};
