import { Grid, Link, Typography } from "@mui/material";

import { DEFAULT_COLUMN_SPACING } from "layout/configuration";

import { ContentCardTextBlock } from "layout/card/ContentCardTextBlock";
import { StyledList } from "common/components/lists/StyledList";
import { TopicOverviewInsetCard } from "modules/Topics/components/TopicOverviewInsetCard";

const DrugOverdoseDeaths = () => {
  const spacing = DEFAULT_COLUMN_SPACING / 2;
  return (
    <Grid container spacing={DEFAULT_COLUMN_SPACING}>
      <Grid item sm={12} lg={6}>
        <ContentCardTextBlock>
          <Typography mb={spacing}>
            Drug overdose deaths continue to increase in Delaware. In 2020, there were 436 drug
            overdose deaths. Based on the data from the Delaware Health Statistics Center (DHSC),
            the average increase in drug overdose death count is 18% every year from 2012 to 2020.
            From the Division of Forensic Science (DFS), the suspected drug overdose death count
            showed a monthly average increase of 18% from November 2022 to January 2023.
          </Typography>
        </ContentCardTextBlock>
      </Grid>
      <Grid item sm={12} lg={6}>
        <TopicOverviewInsetCard
          title="Looking for substance abuse treatment?"
          overline="Substance Use Disorder Support"
          variant="emphasis"
          sx={{ mb: spacing }}
        >
          <Typography mb={1}>Delaware has resources available</Typography>
          <StyledList>
            <li>
              <Link href="//www.helpisherede.com/">Visit Help is Here Delaware for support</Link>
            </li>
            <li>
              Call <Link href="tel:1-833-946-7333">(833) 9-HOPEDE</Link> to get the help you need
            </li>
          </StyledList>
        </TopicOverviewInsetCard>
      </Grid>
    </Grid>
  );
};

export default DrugOverdoseDeaths;
