import { ComponentType } from "react";

import { TopicDictionary } from "app/topics/util/types";

import Diabetes from "./items/Diabetes";
import DrugOverdoseDeaths from "./items/DrugOverdoseDeaths";
import MentalHealthSubstanceUseOverview from "./items/MentalHealthSubstanceUseOverview";

export const topicOverviewDictionary: TopicDictionary<ComponentType> = {
  "mental-health-substance-use": MentalHealthSubstanceUseOverview,
  "drug-overdose-deaths": DrugOverdoseDeaths,
  diabetes: Diabetes
};
