import { ComponentType } from "react";

import { AdditionalInfoProps } from "./items/types";
import { TopicDictionary } from "app/topics/util/types";

import AsthmaAdditionalResources from "../../util/elementHelpers/Asthma/Overview/additionalResources";

import MentalHealthSubstanceUseAdditionalInfo from "./items/MentalHealthSubstanceUseAdditionalInfo";
import YouthUseOfPrescriptionPainMedsAdditionalInfo from "./items/YouthUseOfPrescriptionPainMedsAdditionalInfo";

export const topicAdditionalInfoDictionary: TopicDictionary<ComponentType<AdditionalInfoProps>> = {
  ["mental-health-substance-use"]: MentalHealthSubstanceUseAdditionalInfo,
  "drug-youth-rx": YouthUseOfPrescriptionPainMedsAdditionalInfo,
  asthma: AsthmaAdditionalResources
};
