"use client";

import { styled } from "@mui/material/styles";

export const StyledList = styled("ul")(({ theme: { spacing } }) => ({
  listStyleType: "circle",
  paddingLeft: 30,
  margin: 0,
  gap: 1,
  "> li": {
    paddingLeft: 0,
    marginBottom: spacing(0.25)
  }
}));
