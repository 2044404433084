import { Box, SvgIconProps, Typography } from "@mui/material";
import isNil from "lodash/isNil";

import { needsAttentionDescription, showNeedsAttentionIcon } from "common/components/Icons/utils";
import { formatValueByUnit } from "common/util/formatHelpers";
import { getValueOptionsFromSi } from "common/util/formatHelpers/statIdentifierHelpers";

import { NeedsAttentionIcon } from "common/components/Icons";
import { SuppressionTooltip } from "common/components/SuppressionTooltip/SuppressionTooltip";
import { IndicatorTableRow } from "../IndicatorTable";

interface Props extends IndicatorTableRow {
  useRatioForValues?: boolean;
}

export const LocationValueWrapper: React.FC<Props> = ({ useRatioForValues, ...row }) => {
  const improvementType = row.benchmarkImprovementType;
  const { locationValue, target, si } = row;
  const _showNeedsAttention = showNeedsAttentionIcon({
    currentValue: locationValue,
    target: target?.value,
    improvementType
  });
  const iconStyle = {
    mr: 1,
    p: 0,
    width: "25px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignSelf: "center",
    my: "auto",
    "&:empty": {
      display: "none"
    }
  };
  const iconSX: Omit<SvgIconProps, "color"> = {
    width: "25px",
    height: "25px",
    fontSize: "medium",
    sx: {
      my: "auto"
    }
  };

  return (
    <>
      {row.locationValue !== undefined && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            my: 0,
            py: 0
          }}
        >
          <Box sx={iconStyle}>
            {_showNeedsAttention && (
              <NeedsAttentionIcon
                {...iconSX}
                title={needsAttentionDescription({
                  lastValue: locationValue,
                  targetValue: target?.value,
                  si
                })}
              />
            )}
          </Box>
          <Typography fontWeight={600} variant="body2" sx={{ my: "auto" }}>
            {useRatioForValues === true ? "1 : " : ""}
            {formatValueByUnit({
              value: locationValue,
              ...getValueOptionsFromSi(si)
            })}
          </Typography>
        </Box>
      )}
      {isNil(locationValue) && <SuppressionTooltip fontSize="small" />}
    </>
  );
};
