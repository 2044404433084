import { useMemo } from "react";
import dynamic from "next/dynamic";

import { ChartProps } from "common/components/charts/Chart/types";

import { StateLoadingIndicator } from "../GeoMap/StateLoadingIndicator";

// dynamic component to get so highcharts resizes correctly
export const DynamicChart: React.FC<ChartProps & { fallback?: JSX.Element | null }> = ({
  fallback,
  ...props
}) => {
  const Component = useMemo(
    () =>
      dynamic(async () => import("common/components/charts/Chart"), {
        loading: () =>
          fallback ?? <StateLoadingIndicator loading={true} width={"100%"} height={300} />,
        ssr: false
      }),
    [fallback]
  );
  return <Component {...props} />;
};
