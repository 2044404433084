import { Link } from "@mui/material";

import { sendGaUserInteractionEvent } from "common/util/googleAnalytics";
import { useIsMobile } from "common/util/hooks/useIsMobile";

interface Props {
  handleClick: () => void;
}

const InvestigateChartClearSelection: React.FC<Props> = ({ handleClick }) => {
  const isMobile = useIsMobile("lg");

  return (
    <Link
      component="button"
      onClick={() => {
        sendGaUserInteractionEvent({
          category: "Charts",
          action: "Investigate Dropdown Clear Selection"
        });
        handleClick();
      }}
      sx={{
        mt: "auto",
        mb: { xs: 0, lg: 2 },
        display: "flex",
        color: "dropdowns.main",
        width: isMobile ? undefined : "fit-content",
        minWidth: "none",
        whiteSpace: "nowrap",
        justifyContent: { xs: "flex-start", lg: "center" },
        flex: { xs: 1, lg: 0 },
        cursor: "pointer"
      }}
      variant="body2"
      underline="hover"
    >
      Clear selection
    </Link>
  );
};

export default InvestigateChartClearSelection;
