import {
  AxisTypeValue,
  PointMarkerOptionsObject,
  SeriesArearangeOptions,
  SeriesLineOptions
} from "highcharts";

import { ChartSeriesOptions, RawChartSeries } from "../Chart/types";
import { MhcTimeSeries } from "graphqlApi/types";

import { demographicColors, yellowGreenBlueViolet } from "theme/colors";
import { colorConfig } from "../util/color/config";
import { NO_DATA_COLOR } from "common/components/charts/util/color";
import { dateAsTime } from "common/components/charts/util/getMinMaxDates";
import { getUtcDateNumber } from "common/util/utcDateFromString";
import { LoadedStat } from "modules/Topics/util/fetchingFunctions/fetchStatsForAllSections";

export const seriesData = (
  dateTimeSeries: Omit<MhcTimeSeries, "timestamps"> & { timestamps?: MhcTimeSeries["timestamps"] }
): [number, number][] => {
  const { values = [], dates = [], timestamps = [] } = dateTimeSeries;
  const series = dates.length ? dates : timestamps;
  return series.map(
    (date: number | string, i: number) =>
      [dateAsTime(date) ?? null, values[i] ?? null] as [number, number]
  );
};

interface Params {
  series: RawChartSeries[];
  seriesType?: AxisTypeValue;
  markersOn?: boolean;
  chartType?: "line" | "spline" | "arearange";
  showConfidenceIntervals?: boolean;
  isAgeSeries?: boolean;
}

export const isRenderableChartSeries = (series: ChartSeriesOptions[]) => {
  return series.some(({ data }) => {
    if (!data) return false;
    return data.length > 1;
  });
};

const getLargestNumber = (str: string): number | null => {
  // Replace non-numeric characters except '-' when it appears as a negative sign
  const cleanedStr = str.replace(/[^0-9-]+/g, "");

  // Extract numbers from the cleaned string
  const numbers = cleanedStr.match(/-?\d+/g) || [];

  // Convert extracted strings to numbers
  const numberArray = numbers.map(Number);

  // Find and return the largest number
  return numberArray.length > 0 ? Math.max(...numberArray) : null;
};

export const colorFromUnitLabel = (name?: string): string | undefined => {
  const toEvaluate = `${name?.toLowerCase() ?? ""}`;
  const { race = {}, ethnicity = {}, sex = {}, grade = {} } = demographicColors;
  if (toEvaluate?.includes("total")) return "#aaa";
  if (toEvaluate?.includes("white")) return race.white;
  if (toEvaluate?.includes("black")) return race.black;
  if (toEvaluate?.includes("asian")) return race.asian;
  if (toEvaluate?.includes("multiple race") || toEvaluate?.includes("multiracial"))
    return race.multiracial;
  if (toEvaluate?.includes("other race")) return race.otherRace;
  if (toEvaluate?.includes("native american")) return race.nativeAmerican;
  if (toEvaluate?.includes("pacific islander") || toEvaluate?.includes("asian or pacific islander"))
    return race.pacificIslander;
  if (toEvaluate?.includes("non-hispanic")) return ethnicity.nonHispanic;
  if (toEvaluate?.includes("hispanic")) return ethnicity.hispanic;
  if (toEvaluate?.includes("female")) return sex.female;
  if (toEvaluate?.includes("male")) return sex.male;
  if (toEvaluate?.includes("patient declined to disclose")) return "#EC829B";
  if (toEvaluate?.includes("grade 8")) return grade.eight;
  if (toEvaluate?.includes("grade 11")) return grade.eleven;
  if (toEvaluate?.includes("data not reported")) return NO_DATA_COLOR;
  return undefined;
};

/**
 * Builds series for line chart from data with properties Highcharts expects
 *
 * @param params
 * @param params.series - Array of series data as LineChartSeries
 * @param params.seriesType - Series type (datetime or category)
 * @param params.markersOn - Whether to show markers on the series
 * @param params.chartType - Chart type (line or spline)
 * @param params.isAgeSeries - Determines if the provided series is of age ranges
 *
 * @param params.showConfidenceIntervals
 * @returns Line or Spline chart series options per series
 * @see LineChartSeries
 *
 */
export const seriesForLineChart = ({
  series,
  seriesType = "datetime",
  markersOn = true,
  chartType = "line",
  showConfidenceIntervals = false,
  isAgeSeries = false
}: Params) => {
  const getAllSeries = () => {
    if (isAgeSeries === false) return series;

    let ageSeries = series
      .filter(({ name, ...series }) => {
        const number = getLargestNumber(name ?? "");
        return number !== null && number <= 100 && series && series.values.length > 0;
      })
      .sort(
        (a, b) => (getLargestNumber(a.name ?? "") ?? 0) - (getLargestNumber(b.name ?? "") ?? 0)
      );
    ageSeries = ageSeries.map((props, index) => {
      let color = yellowGreenBlueViolet[index];
      if (ageSeries.length <= 6) color = yellowGreenBlueViolet[index * 2];
      return { ...props, color };
    });
    const nonAgeSeries = series.filter(({ name }) => {
      const number = getLargestNumber(name ?? "");
      return !(number !== null && number <= 100);
    });
    return [...ageSeries, ...nonAgeSeries];
  };
  return getAllSeries().flatMap((props, i) => {
    const {
      dates = [],
      timestamps = [],
      values,
      name,
      confidenceIntervals = [],
      color,
      dashStyle,
      type,
      accessibility,
      lineWidth,
      visible = true
    } = props;
    const _series: ChartSeriesOptions[] = [
      {
        color: color || colorFromUnitLabel(name) || colorConfig.set[i],
        dashStyle: dashStyle ?? undefined,
        type: type || chartType || "line",
        pointInterval: 1,
        data:
          seriesType === "category"
            ? (values as number[])
            : seriesData({
                dates,
                timestamps,
                values
              }),
        name,
        lineWidth: lineWidth ?? 3,
        accessibility: accessibility,
        marker: {
          enabled: markersOn,
          radius: 5,
          symbol: "circle"
        },
        visible
      } as SeriesLineOptions
    ];
    if (showConfidenceIntervals && confidenceIntervals.length) {
      _series.push({
        id: [name?.toLowerCase()?.replaceAll(" ", "-"), "confidence", "intervals"].join("-"),
        name: `${name ?? ""} - Confidence Intervals`,
        linkedTo: ":previous",
        data: confidenceIntervals.map(({ lower, upper }: { lower: number; upper: number }, i) => [
          getUtcDateNumber(dates[i]) ?? i,
          lower,
          upper
        ]),
        type: "arearange",
        lineWidth: 0,
        fillOpacity: 0.2,
        color: color || colorFromUnitLabel(name) || colorConfig.set[i],
        showInLegend: false,
        marker: {
          enabled: false
        },
        point: {
          events: {
            mouseOver: function (event) {
              event.preventDefault();
              event.stopPropagation();
            }
          }
        },
        states: {
          hover: {
            enabled: false
          }
        }
      } as SeriesArearangeOptions);
    }
    return _series;
  });
};

export type ChartSeriesOptionsDictionaryItem = ChartSeriesOptions & {
  format?: "category" | "timeseries";
  lineWidth?: number;
  marker?: PointMarkerOptionsObject;
};
export type ChartSeriesOptionsDictionary = Record<string, ChartSeriesOptionsDictionaryItem>;
export type StatsToSeriesParams = {
  stats: (LoadedStat & { _id?: string | null })[];
  options: ChartSeriesOptionsDictionary;
  useIdAsName?: boolean;
};

/**
 * Converts an array of locationStats (MhcLocationStatWithDataSeriesFragment) to an array of
 * Highchart series options objects
 *
 * @param params
 * @param params.stats - Array of MhcLocationStatWithDataSeriesFragment objects
 * @param params.options - Options that will be applied to each series
 * @param params.useIdAsName - Determines if the id of each stat should be used as the name
 *
 * @returns Array of Highchart series options objects
 *
 * @see MhcLocationStatWithDataSeriesFragment
 * @see ChartSeriesOptions
 *
 */
export const statsToSeries = ({ stats, options, useIdAsName }: StatsToSeriesParams) => {
  return stats.map(({ statIdentifier, timeSeries, id, _id, location }, i) => {
    const { name, type, format, color, dashStyle, lineWidth, marker, ...additionalOptions } =
      (options[statIdentifier.id ?? ""] ||
        options[[statIdentifier.id, location?.id ?? ""].join("-")]) ??
      {};
    const { values = [], dates = [] } = timeSeries ?? {};
    return {
      _id,
      color: color || colorConfig.set[i],
      dashStyle: dashStyle ?? null,
      type: type ?? "line",
      data:
        format === "category"
          ? (values as number[])
          : seriesData({
              dates,
              timestamps: [],
              values
            }),
      name: useIdAsName ? id : name ?? "",
      lineWidth: lineWidth ?? 3,
      marker: marker ?? {
        enabled: true,
        radius: 5,
        symbol: "circle"
      },
      visible: true,
      ...additionalOptions
    } as SeriesLineOptions & { _id?: string | null };
  });
};
