import RateReviewIcon from "@mui/icons-material/RateReview";
import { Link, Stack, Typography } from "@mui/material";

interface Props {
  /** Survey href */
  href: string;
  /** Title for the survey link */
  title?: string;
}

export const HotjarSurveyLink = ({ href, title = "Share feedback about this chart" }: Props) => {
  return (
    <Link
      color="component.links"
      href={href}
      component="a"
      target="blank"
      sx={{ width: "min-content" }}
    >
      <Stack direction="row" gap={1}>
        <RateReviewIcon fontSize="small" sx={{ my: "auto" }} />
        <Typography
          color="component.links"
          variant="body2"
          component="span"
          sx={{ width: "max-content" }}
        >
          {title}
        </Typography>
      </Stack>
    </Link>
  );
};
