import isNil from "lodash/isNil";

import {
  FetchKpiOptions,
  KpiPropsAndRelatedKpis,
  StatIdTopicInfoDictionary,
  TopicKpiGroupProps
} from "./types";
import { MhcAttributionFragment, MhcTopicFragment } from "graphqlApi/types";

import { updateKpiTitlesWithTheSameName } from "./util";
import { logError } from "common/util/consoleHelpers";
import { getLatestDateFromArray } from "common/util/getLatestDate";

import {
  getLoadedStatDictionaryForLocation,
  LoadedLocationStatDictionaryByLocation
} from "../fetchStatsForAllSections";
import { getStatIdsForTopic } from "..";
import { createKpiProps } from "./createKpiProps";
import { fetchKpiDataForStatIds } from "./fetchKpiDataForStatIds";

interface Args {
  statIds: string[];
  loadedStats?: LoadedLocationStatDictionaryByLocation;
  locationId: string;
  options?: FetchKpiOptions;
}

type LoadedStatAttributionDictionary = Record<string, MhcAttributionFragment[]>;
export const fetchKpiProps = async ({ statIds, loadedStats, locationId, options = {} }: Args) => {
  let latestDate;
  let updatedDate;
  let attributions;
  let attributionsByStat: LoadedStatAttributionDictionary = {};
  let kpis: KpiPropsAndRelatedKpis[] = [];
  const shouldFetchData = isNil(loadedStats) || Object.keys(loadedStats).length === 0;
  if (shouldFetchData) {
    if (!statIds.length) logError("No statIds provided to fetchKpiProps");
    const kpiData = await fetchKpiDataForStatIds({ locationId, statIds, options });
    kpis = kpiData.kpis;
    latestDate = kpiData.latestDate ?? null;
    updatedDate = kpiData.updatedDate ?? null;
    attributions = kpiData.attributions ?? [];
    attributionsByStat = kpiData.attributionsByStat ?? {};
  } else {
    const loadedStatsForLocation = getLoadedStatDictionaryForLocation(locationId, loadedStats);
    latestDate = getLatestDateFromArray(
      Object.values(loadedStatsForLocation).map(({ lastUpdatedOn }) => lastUpdatedOn ?? null)
    );
    kpis = await Promise.all(
      Object.values(loadedStatsForLocation).map(
        async (loadedStat) => await createKpiProps({ locationId, ...loadedStat, options })
      )
    );
    kpis = updateKpiTitlesWithTheSameName(kpis);
    Object.entries(loadedStatsForLocation).forEach(
      ([statId, stat]) => (attributionsByStat[statId] = stat.statIdentifier.attributions ?? [])
    );
  }

  return {
    kpis,
    granularity: options.granularity,
    latestDate,
    updatedDate,
    attributions,
    attributionsByStat
  };
};

type GetKpiData = Partial<{
  statIds: string[];
  locationId: string;
  statIdTopicInfoDictionary?: StatIdTopicInfoDictionary;
  getRelatedKpis?: boolean;
  forceRelatedStatTitle?: boolean;
  options?: FetchKpiOptions;
  loadedStats?: LoadedLocationStatDictionaryByLocation;
}>;
export const getKpiData = async ({
  statIds = [],
  locationId,
  statIdTopicInfoDictionary = undefined,
  getRelatedKpis = false,
  forceRelatedStatTitle = false,
  loadedStats,
  options = {}
}: GetKpiData): Promise<TopicKpiGroupProps> => {
  if (!locationId) return {};
  if (!statIds.length && !loadedStats) return {};
  return await fetchKpiProps({
    statIds,
    locationId,
    loadedStats,
    options: {
      statIdTopicInfoDictionary,
      loadRelatedStats: getRelatedKpis,
      forceRelatedStatTitle,
      ...options
    }
  });
};

export const getKpiDataForTopic = async ({
  topic,
  locationId
}: {
  topic: MhcTopicFragment;
  locationId: string;
}) => {
  const { statIds, statIdTopicInfoDictionary } = getStatIdsForTopic({ topic });
  return await getKpiData({
    locationId,
    statIds,
    statIdTopicInfoDictionary
  });
};
