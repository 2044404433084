"use client";

import React from "react";

import { useIsMobile } from "common/util/hooks/useIsMobile";

import { DataTable } from "common/components/DataTable";
import { getColumns } from "./columns";
import { IndicatorTableProps } from "./IndicatorTable";

export const DesktopIndicatorTable: React.FC<IndicatorTableProps> = ({
  additionalTableHeaderRow,
  columnsToShow,
  columnWidths,
  location,
  onRowClick,
  renderGhostColumns,
  rows,
  sorting,
  title,
  useCounty,
  useRatioForValues
}) => {
  return (
    <DataTable
      onRowClick={onRowClick}
      infoIconVariant="cpr"
      data={rows.map((row, i) => ({ ...row, id: String(i) }))}
      columns={Object.values(
        getColumns({
          title,
          location,
          useCounty,
          useRatioForValues,
          columnWidths,
          columnsToShow,
          renderGhostColumns,
          isMobile: useIsMobile()
        })
      )}
      sorting={sorting}
      additionalTableHeaderRow={additionalTableHeaderRow}
    />
  );
};
