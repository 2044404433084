"use client";

import React, { ReactNode, useEffect, useState } from "react";

import { DateFromApi } from "graphqlApi/customTypes";
import { DataTableSortingProps } from "common/components/DataTable/types";
import {
  MhcLocationFragment,
  MhcLocationStat,
  MhcLocationStatFragment,
  MhcStatIdentifierFragment,
  MhcStatImprovementEnum,
  MhcTarget,
  MhcTargetFragment,
  MhcTimeSeriesGranularityEnum,
  MhcTopicFragment
} from "graphqlApi/types";

import { logError } from "common/util/consoleHelpers";
import { useIsMobile } from "common/util/hooks/useIsMobile";
import { LoadedDateSeries } from "modules/Topics/util/fetchingFunctions/fetchStatsForAllSections";

import { DesktopIndicatorTable } from "./DesktopIndicatorTable";
import { IndicatorTableContentWrapperProps } from "./IndicatorTableContentWrapper";

export const baseColumnIds = ["indicator", "location"];
export const analyticalColumnIds = ["state", "national", "percent_change", "trendline", "target"];
export const columnIdsOptions = [...baseColumnIds, ...analyticalColumnIds];
export const allPossibleColumns = [
  "topic",
  ...baseColumnIds,
  ...analyticalColumnIds,
  "primaryStat",
  "relatedStat"
];
export type IndicatorColumnIds = (typeof allPossibleColumns)[number];

export interface IndicatorTableRow {
  id: string;
  si: MhcStatIdentifierFragment;
  locationStat?: MhcLocationStatFragment;
  relatedSi?: MhcStatIdentifierFragment | null;
  locationValue?: number | null;
  latestDate?: DateFromApi;
  timeSeries?: LoadedDateSeries | null;
  benchmark?: number;
  range?: string;
  stateValue?: number;
  nationalValue?: number;
  relatedValue?: number | null;
  benchmarkImprovementType?: MhcStatImprovementEnum | null | undefined;
  needsAttention?: boolean;
  improving?: boolean;
  granularity?: MhcTimeSeriesGranularityEnum;
  statSentence?: MhcLocationStat["sentence"];
  percentageChange?: MhcLocationStat["percentageChange"];
  target?: {
    value?: number | null;
    object?: MhcTarget | MhcTargetFragment | null;
  } | null;
  topic?: Pick<MhcTopicFragment, "href" | "name">;
}

export interface IndicatorTableProps {
  additionalTableHeaderRow?: ReactNode;
  columnsToShow?: IndicatorColumnIds[];
  columnWidths?: IndicatorTableContentWrapperProps["columnWidths"];
  id?: string;
  location: MhcLocationFragment;
  onRowClick?: (id: string, row: IndicatorTableRow) => void;
  renderGhostColumns?: boolean;
  rows: IndicatorTableRow[];
  showImprovement?: boolean;
  showTargets?: boolean;
  showTrends?: boolean;
  sorting?: DataTableSortingProps;
  title?: string;
  useCounty?: boolean;
  useRatioForValues?: boolean;
}

export const IndicatorTable: React.FC<IndicatorTableProps> = ({
  additionalTableHeaderRow,
  columnsToShow,
  renderGhostColumns,
  rows,
  title,
  ...props
}) => {
  const [sortedRows, setSortedRows] = useState<IndicatorTableRow[]>(rows);
  const [direction, setDirection] = useState<"asc" | "desc">("asc");
  const [sortingField, setSortingField] = useState<IndicatorColumnIds | null>(
    props.sorting?.field ?? "indicator"
  );

  const getSortValue = (
    sortingField: IndicatorColumnIds,
    row: IndicatorTableRow
  ): number | string => {
    switch (sortingField) {
      case "topic":
        return row?.topic?.name ?? "";
      case "location":
        return row?.locationValue ?? 0;
      case "indicator":
        return row?.si.name;
      case "national":
        return row?.nationalValue ?? 0;
      case "state":
        return row?.stateValue ?? 0;
      default:
        logError(`TRYING TO SORT INDICATOR TABLE BY UNSUPPORTED COLUMNS!!`);
        return 0;
    }
  };

  useEffect(() => {
    if (sortingField && rows !== undefined && rows.length > 0) {
      setSortedRows([
        ...rows.sort((rowA, rowB) => {
          const sortValueA = getSortValue(sortingField, rowA);
          const sortValueB = getSortValue(sortingField, rowB);
          if (sortValueA < sortValueB) {
            return direction === "desc" ? 1 : -1;
          }
          if (sortValueA > sortValueB) {
            return direction === "desc" ? -1 : 1;
          }
          return 0;
        })
      ]);
    }
  }, [rows, direction, sortingField]);

  const sorting: DataTableSortingProps = {
    field: sortingField,
    direction: direction,
    changeDirection: setDirection,
    changeField: (nv: string) => {
      setSortingField(nv as IndicatorColumnIds);
    }
  };

  // Update table props for mobile
  const isMobile = useIsMobile();
  let _columnsToShow = columnsToShow;
  let _renderGhostColumns = renderGhostColumns;
  if (isMobile) {
    _columnsToShow = [...baseColumnIds, "percent_change"];
    _renderGhostColumns = false;
  }

  return (
    <DesktopIndicatorTable
      {...props}
      columnsToShow={_columnsToShow}
      rows={sortedRows}
      sorting={sorting}
      title={title}
      additionalTableHeaderRow={additionalTableHeaderRow}
      renderGhostColumns={_renderGhostColumns}
    />
  );
};
