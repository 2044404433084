import { ReactNode } from "react";
import { Box, BoxProps, Typography, TypographyProps } from "@mui/material";

export type ContentCardTextBlockProps = {
  title?: string;
  sx?: BoxProps["sx"];
  children?: ReactNode;
  variant?: "h4" | "h5" | "h6";
  titleProps?: TypographyProps;
};

export const ContentCardTextBlock: React.FC<ContentCardTextBlockProps> = ({
  title,
  sx = { mb: { md: 3 } },
  titleProps = {},
  variant = "h4",
  children
}) => {
  const _titleProps = {
    variant,
    component: "h6",
    mb: 0.75,
    ...titleProps
  } as TypographyProps;
  return (
    <Box sx={{ "p:not(last-child)": { mb: 1 }, ...sx }}>
      {title && <Typography {..._titleProps}>{title}</Typography>}
      {children}
    </Box>
  );
};
