import { addDays } from "date-fns/addDays";
import { endOfMonth } from "date-fns/endOfMonth";
import { formatISO9075 } from "date-fns/formatISO9075";
import { previousSaturday } from "date-fns/previousSaturday";
import { startOfMonth } from "date-fns/startOfMonth";
import { sub } from "date-fns/sub";
import { subDays } from "date-fns/subDays";

import { MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { formatDateByGranularity } from "./formatHelpers";
import { normalizeDate } from "./utcDateFromString";

export const dateToIsoString = (date: Date) => formatISO9075(date, { representation: "date" });

const currentDate = (date?: string) => {
  if (date) {
    return normalizeDate(new Date(date));
  }
  return normalizeDate(new Date());
};

const convertDates = ({ startsOn, endsOn }: { startsOn: Date; endsOn: Date }) => {
  return {
    startsOn: dateToIsoString(startsOn) ?? null,
    endsOn: dateToIsoString(endsOn) ?? null
  };
};

export const lastWeekDates = (date?: string) => {
  const dateObj = currentDate(date);
  const endsOn = date ? dateObj : sub(previousSaturday(dateObj), { weeks: 0 });
  const startsOn = sub(endsOn, { days: 6 });
  return convertDates({ endsOn, startsOn });
};

export const lastMonthDates = (date?: string) => {
  const dateObj = currentDate(date);
  const weekStartDate = sub(dateObj, { weeks: 1 });
  const startsOn = date
    ? startOfMonth(weekStartDate)
    : startOfMonth(sub(weekStartDate, { months: 1 }));
  const endsOn = endOfMonth(startsOn);
  return convertDates({ endsOn, startsOn });
};

export const weekStartDate = (date: Date) => {
  return subDays(date, 6);
};

export const weekEndDate = (date: Date) => {
  return addDays(date, 6);
};

export const weekStartAndEndDates = ({
  start,
  end,
  formatAsString = false
}: {
  start?: Date;
  end?: Date;
  formatAsString?: boolean;
}) => {
  let dates;
  if (start && !end) {
    dates = { startDate: start, endDate: weekEndDate(start) };
  } else if (!start && end) {
    dates = { startDate: weekStartDate(end), endDate: end };
  } else {
    throw new Error("weekStartAndEndDates function given incorrect parameters");
  }
  if (dates && formatAsString) {
    const granularity = MhcTimeSeriesGranularityEnum.Week;
    return [
      formatDateByGranularity({ value: dates.startDate, granularity }),
      formatDateByGranularity({ value: dates.endDate, granularity })
    ].join(" - ");
  }
  if (!dates) throw new Error("weekStartAndEndDates function did not return dates");
  return dates;
};
