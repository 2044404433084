import { ChartColorConfig } from "../../types";

export const CHART_COLORS = [
  "#1E90FF",
  "#E5B303",
  "#85C390",
  "#A0DEE7",
  "#B4AFFF",
  "#F7C390",
  "#296069",
  "#4E1199",
  "#9C6F4C",
  "#FF6584"
];
export const colorConfig = {
  primary: CHART_COLORS[0],
  secondary: CHART_COLORS[1],
  average: "#b5daff",
  trend: "#888888",
  bars: [
    // We should add these for each color in set...and generate the border/text
    // programmatically with a package or a function
    { fill: CHART_COLORS[0], border: "#007AF5", text: "#70B8FF" },
    { fill: CHART_COLORS[1], border: "#C99E03", text: "#FCD64A" }
  ],
  benchmark: "#CCCCCC",
  set: CHART_COLORS
} as ChartColorConfig;
