import { useMemo } from "react";
import { Stack, Typography } from "@mui/material";

import { STATE_LOCATION_NAME } from "common/constants";
import { capitalizeFirstLetter } from "common/util/capitalizeFirstLetter";
import { useIsMobile } from "common/util/hooks/useIsMobile";

import { HotjarSurveyLink } from "../../HotjarSurveyLink/HotjarSurveyLink";

export interface InvestigateChartTitlesProps {
  groupTitle?: string | null;
  hideGroupTitle?: boolean;
  locationName?: string | null;
  stratificationTitle?: string | null;
  subtitle?: string | null;
  title: string;
  appendToTitle?: string;
  hotjarSurveyLink?: string;
  hotjarSurveyTitle?: string;
}

export const getChartTitle = ({
  groupTitle,
  hideGroupTitle = false,
  locationName,
  stratificationTitle,
  title,
  appendToTitle
}: InvestigateChartTitlesProps) => {
  let _title = title;
  if (
    groupTitle &&
    title !== groupTitle &&
    !title.includes(groupTitle) &&
    hideGroupTitle === false
  ) {
    _title += ` (${groupTitle})`;
  }
  if (stratificationTitle) {
    _title += ` by ${stratificationTitle}`;
  }
  if (locationName && !(title.includes(locationName) || title.includes(STATE_LOCATION_NAME))) {
    _title += ` in ${locationName}`;
  }
  if (appendToTitle && !title.includes(appendToTitle)) {
    _title += appendToTitle;
  }
  return _title;
};
const InvestigateChartTitles: React.FC<InvestigateChartTitlesProps> = ({
  subtitle,
  hotjarSurveyLink,
  hotjarSurveyTitle,
  ...props
}) => {
  const title = useMemo(() => getChartTitle(props), [props]);
  const isMobile = useIsMobile();
  return (
    <Stack spacing={0.5}>
      <Typography variant="h4" data-testid={"InvestigationTitle"}>
        {title}
      </Typography>
      {subtitle && (
        <Stack direction={isMobile === true ? "column" : "row"} mt={1} gap={5}>
          <Typography component="p" variant="body2">
            {capitalizeFirstLetter(subtitle)}
          </Typography>
          {hotjarSurveyLink && (
            <Stack
              alignContent="end"
              justifyContent="end"
              ml={isMobile === false ? "auto" : undefined}
            >
              <HotjarSurveyLink href={hotjarSurveyLink} title={hotjarSurveyTitle ?? undefined} />
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default InvestigateChartTitles;
