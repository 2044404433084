import { Box, BoxProps, Stack, Typography } from "@mui/material";

import { AboutTheDataContent } from "../util/elementHelpers/dashboard/types";
import { MhcAttributionFragment } from "graphqlApi/types";

import { ContentCardTextBlock } from "layout/card/ContentCardTextBlock";
import { MhcAccordion, MhcAccordionProps } from "component/accordion/TextAccordion";
import { StatAttributionList } from "modules/ECDC/components/StatAttributionList";

export interface TopicSectionAboutTheDataProps {
  aboutContent?: AboutTheDataContent | null;
  dataSources?: MhcAttributionFragment[];
  sx?: BoxProps["sx"];
  title?: string;
  uiLocation?: string;
  variant?: MhcAccordionProps["variant"];
}

export const TopicSectionAboutTheData: React.FC<TopicSectionAboutTheDataProps> = ({
  aboutContent,
  dataSources,
  sx = {},
  title = "",
  uiLocation,
  variant = "grey"
}) => {
  const defaultTitle = dataSources?.length ? "About the Data" : "Additional Information";
  return (
    <Box sx={sx}>
      <MhcAccordion
        variant={variant}
        id={`${title.replace(" ", "-")}_about-info`}
        title={
          <Typography fontWeight={700} variant="h6">
            {title || defaultTitle}
          </Typography>
        }
        label={title || defaultTitle}
        uiLocation={uiLocation ?? "Chart"}
      >
        <Stack gap={4}>
          {aboutContent && aboutContent.length > 0 && (
            <Stack gap={2}>
              {aboutContent.map(({ title, text }) => (
                <ContentCardTextBlock title={title} key={title} variant="h6" sx={{ mb: 0 }}>
                  <Typography>{text}</Typography>
                </ContentCardTextBlock>
              ))}
            </Stack>
          )}
          {dataSources && dataSources.length > 0 && (
            <Box>
              <Typography variant="h6" mb={0.75}>
                Data Sources
              </Typography>
              <StatAttributionList
                attributions={dataSources}
                showDivider={false}
                showTitle={false}
                controlWidth={false}
              />
            </Box>
          )}
        </Stack>
      </MhcAccordion>
    </Box>
  );
};
