import { ReactNode } from "react";
import { Box, SvgIconProps, Typography } from "@mui/material";

import { ICON_IDS, IconId, IconIdEnum } from "common/components/Icons/utils";

import { ImprovingIcon, NeedsAttentionIcon } from "common/components/Icons";
import ImprovingPopoverContent from "../Icons/Improving/PopoverContent";
import NeedsAttentionPopoverContent from "../Icons/NeedsAttention/PopoverContent";
import { PopoverContainer } from "../PopoverContainer";

const KeyBox: React.FC<{
  icon: React.ReactNode;
  title: string;
  content: ReactNode;
  id: string;
}> = ({ id, icon, title, content }) => {
  return (
    <PopoverContainer label={`${title} Definition`} id={`${id}-popover`} content={content}>
      <Box
        role="button"
        aria-pressed="false"
        aria-label={`${title} Legend/Key Item`}
        name={title}
        component={"button"}
        type="button"
        sx={({ palette }) => ({
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          ml: 1.5,
          px: 1.5,
          py: 1,
          borderColor: "rgba(0,0,0,0.2)",
          borderStyle: "solid",
          borderWidth: "0.24px",
          borderRadius: "3.87px",
          background: palette.common.white,
          cursor: "pointer",
          "&:hover, &:focus": {
            backgroundColor: palette.action.disabledBackground
          }
        })}
      >
        <Box display="flex" alignItems="center" className="svg-icon-wrapper">
          {icon}
        </Box>
        <Typography variant="body3" sx={{ ml: 1 }}>
          {title}
        </Typography>
      </Box>
    </PopoverContainer>
  );
};

export const IndicatorTableKey: React.FC<{ iconsToShow?: IconId[] }> = ({
  iconsToShow = ICON_IDS
}) => {
  const iconSX: Omit<SvgIconProps, "color"> = {
    width: "25px",
    height: "25px",
    fontSize: "small",
    sx: {
      my: "auto"
    }
  };
  return (
    <Box display="flex" ml="auto" alignItems="center">
      <Typography variant="body3" fontWeight={400} sx={{ py: 1 }}>
        Table Key
      </Typography>
      {iconsToShow.includes(IconIdEnum.NeedsAttention) && (
        <KeyBox
          id="needs-attention"
          icon={<NeedsAttentionIcon {...iconSX} tooltipped={false} />}
          title="Needs Attention"
          content={<NeedsAttentionPopoverContent />}
        />
      )}
      {iconsToShow.includes(IconIdEnum.Improving) && (
        <KeyBox
          id="improving"
          icon={<ImprovingIcon {...iconSX} tooltipped={false} />}
          title="Improving"
          content={<ImprovingPopoverContent />}
        />
      )}
    </Box>
  );
};
