import { getUtcDateFromString, normalizeDate } from "common/util/utcDateFromString";

const toMs = (value: number): number => value * 1000;

export const dateAsTime = (date: number | string) => {
  if (typeof date === "string") {
    const _date = getUtcDateFromString(date) ?? new Date(date);
    return normalizeDate(_date).getTime();
  } else {
    return toMs(date);
  }
};

export const getMinMaxDates = (dates: (number | string)[] = []) => [
  dateAsTime(dates[0] as string | number),
  dateAsTime(dates[dates.length - 1] as string | number)
];
