import {
  TopicDashboardIndicatorTable,
  TopicDashboardTableSectionConfig
} from "../elementHelpers/dashboard/types";
import { MhcLocationBaseFragment } from "graphqlApi/types";

import { fetchDataForTable } from "common/components/IndicatorTable/util/fetchTableRows";
import { getDataDatesFromRows } from "common/components/IndicatorTable/util/getDataDatesFromRows";
import { getMaxDateFromAttributions } from "common/util/attributions";

import { geographySupportsSection } from "./fetchSectionData";
import {
  getLoadedStatDictionaryForLocation,
  LoadedLocationStatDictionaryByLocation
} from "./fetchStatsForAllSections";

interface Args {
  tableConfig: TopicDashboardTableSectionConfig | null;
  location: MhcLocationBaseFragment;
  loadedStats?: LoadedLocationStatDictionaryByLocation;
}
export const fetchTableData = async ({
  tableConfig,
  location,
  loadedStats
}: Args): Promise<TopicDashboardIndicatorTable | null> => {
  if (!tableConfig || !tableConfig?.statIdConfigs) return null;

  const {
    statIdConfigs,
    supportedGeographies,
    title = "",
    id = "",
    showWithNoData = true,
    introText = "",
    props = {}
  } = tableConfig;

  const { geography } = location;
  const availableForGeography = geographySupportsSection(supportedGeographies, geography);
  if (!showWithNoData && !availableForGeography) return null;

  const rows = await fetchDataForTable({
    locationId: location.id,
    statIdConfigs,
    loadedStats: getLoadedStatDictionaryForLocation(location.id, loadedStats),
    showWithNoData
  });
  const attributions = rows.flatMap(({ si }) => si.attributions ?? []);
  return {
    id,
    title,
    dataAvailable: availableForGeography,
    latestDate: getDataDatesFromRows(rows),
    updatedDate: getMaxDateFromAttributions(attributions)?.getTime() ?? null,
    props: {
      ...props,
      introText,
      location,
      showTargets: geography !== "state",
      title,
      rows
    }
  };
};
